import { HyruleToken } from '@nintendo/hyrule-react-commons';
import dayjs from 'dayjs';
import {
    ErrorCodeApiModel,
    ErrorCodeApiModelResponse,
    ErrorCodeOccurrenceApiModel,
    ErrorCodeRequestModel,
} from './models';
import { BaseApi } from './BaseApi';
import { HttpStatusCode } from '../shared/HttpStatusCode';
import { ErrorCodeModel } from '../components/device/error-codes/ErrorCodeModel';

export class ErrorCodeApi {
    static async getErrorCodes(
        serialNumber: string,
        token: HyruleToken,
        correlationId: string,
    ): Promise<ErrorCodeApiModelResponse> {
        // Limit the list to Error Codes received in the last 7 days.
        const now: dayjs.Dayjs = dayjs();
        const sevenDaysAgoEpoch: number = now.clone().add(-7, 'days').unix();
        const nowEpoch: number = now.clone().unix();

        // From those Error Codes, limit the maxmimum number unique Error Codes shown to 5.
        const maxUniqueErrorCodes = 5;

        // For each unique Error Code, only show the 10 most recent occurrences.
        const maxUniqueErrorCodeDetails = 10;

        const request: ErrorCodeRequestModel = {
            from_epoch: sevenDaysAgoEpoch,
            to_epoch: nowEpoch,
            max_details: maxUniqueErrorCodeDetails,
            max_summary: maxUniqueErrorCodes,
            serial_number: serialNumber,
        };

        const { deviceApiUrls } = BaseApi.ApiConfig();
        const url: string = deviceApiUrls.errorCodes();
        const init: RequestInit = await BaseApi.MulesoftRequestInit(
            'POST',
            token,
            correlationId,
            request,
        );

        const isEmptyResponse = (model: ErrorCodeApiModel): boolean =>
            !model || !model.error_codes || !model.error_codes.length;
        const emptyResponse: ErrorCodeApiModel = {
            error_codes: [],
        } as unknown as ErrorCodeApiModel;

        // Use cases of interest
        // 1. It passes and we get the associated error codes (or HTTP 200 OK).
        // 2. No results were found for the given serial number (or HTTP 204, 404, 200 with text = No Data).
        // 3. Any other error.
        try {
            // Make the API call and parse the response. If the response is falsy, then we'll map an empty object.
            const response: Response = await fetch(url, init);

            // If the response.json() call fails (because it's not valid JSON and
            // is falsy, text, etc) for any reason, then simply map an empty array.
            let model: ErrorCodeApiModel;
            try {
                const jsonResponse: ErrorCodeApiModel = await response.json();
                model = isEmptyResponse(jsonResponse) ? emptyResponse : jsonResponse;
            } catch {
                model = emptyResponse;
            }

            // If the array exists, is empty, and we get a successful response OR an HTTP 404 Not Found,
            // then ensure we indicate that we should be displaying the Not Found screen later.
            const isEmptyAndOkResponse: boolean = isEmptyResponse(model) && response.ok;
            const isNotFoundResponse: boolean = response.status === HttpStatusCode.NotFound;
            const displayNotFoundScreen: boolean = isEmptyAndOkResponse || isNotFoundResponse;

            // If the response is NOT ok, and we're talking about something other than an HTTP 404 Not Found, then
            // ensure we indicate that we should be displaying the Server Error screen later.
            const displayServerErrorScreen: boolean = !response.ok && !isNotFoundResponse;

            return {
                notFound: displayNotFoundScreen,
                serverError: displayServerErrorScreen,
                model,
            } as ErrorCodeApiModelResponse;
        } catch (error) {
            console.log(`Error Codes API Error: ${error}`);

            return {
                notFound: false,
                serverError: true,
                model: emptyResponse,
            } as ErrorCodeApiModelResponse;
        }
    }

    static mapToErrorCodeModels(model: ErrorCodeApiModel): ErrorCodeModel[] {
        if (!model || !model.error_codes) {
            return [];
        }

        const errorCodeModels: ErrorCodeModel[] = [];
        const errorCodeDictionary: { [code: string]: ErrorCodeOccurrenceApiModel[] } = {};

        // Throw everything into a dictionary so we can consolidate any duplicate error codes
        // that may or may not come back from the API.
        for (let i = 0; i < model.error_codes.length; i++) {
            const entry = model.error_codes[i];
            // If an entry for this Error Code doesn't exist, then initialize it.
            if (!errorCodeDictionary[entry.error_code]) {
                errorCodeDictionary[entry.error_code] = [];
            }

            errorCodeDictionary[entry.error_code].push(...entry.error_details);
        }

        // For each unique error code, ensure that their occurrences are ordered in desc order.
        for (let i = 0; i < Object.keys(errorCodeDictionary).length; i++) {
            const code = Object.keys(errorCodeDictionary)[i];
            const occurrencesSortedByDesc: ErrorCodeOccurrenceApiModel[] = errorCodeDictionary[
                code
            ].sort((a, b) => dayjs(b.time_occurred).unix() - dayjs(a.time_occurred).unix());
            const model: ErrorCodeModel = {
                // TODO: I'm not really sure why we have this here since it can be calculated, so
                // maybe look at scrapping this later?
                count: occurrencesSortedByDesc.length,
                datesOccurred: occurrencesSortedByDesc.map((x) => dayjs(x.time_occurred)),
                gamesUsed: occurrencesSortedByDesc.map((x) =>
                    x.game_played_during_occurrence
                        ? x.game_played_during_occurrence
                        : 'Unavailable',
                ),
                errorCode: code,
            };

            errorCodeModels.push(model);
        }

        return this.sortErrorCodeModels(errorCodeModels);
    }

    private static sortErrorCodeModels(errorCodeModels: ErrorCodeModel[]): ErrorCodeModel[] {
        return errorCodeModels.sort((a, b) => {
            const mostRecentEpochA: dayjs.Dayjs = a.datesOccurred.sort(
                (a, b) => b.unix() - a.unix(),
            )[0];
            const mostRecentEpochB: dayjs.Dayjs = b.datesOccurred.sort(
                (a, b) => b.unix() - a.unix(),
            )[0];
            return mostRecentEpochB.unix() - mostRecentEpochA.unix();
        });
    }
}
