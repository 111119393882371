import './StatusScreen.scss';
import React, { FunctionComponent, ReactElement } from 'react';

export interface StatusScreenProps {
    dataCy?: string;
    statusImage: ReactElement;
    statusMessage?: string;
    statusTitle?: string;
}

const StatusScreen: FunctionComponent<StatusScreenProps> = ({
    dataCy,
    statusImage,
    statusMessage,
    statusTitle,
}) => (
    <div data-cy={dataCy} className="status-screen">
        <div className="status-image">{statusImage}</div>
        <div className="status-title">{statusTitle}</div>
        <div className="status-message">{statusMessage}</div>
    </div>
);

export default StatusScreen;
