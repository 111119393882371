import React, { useContext, useMemo, useState } from 'react';
import { History } from 'history';
import { v4 as uuidv4 } from 'uuid';
import { HyruleToken } from '@nintendo/hyrule-react-commons';
import Utils from '../../shared/Utils';
import { NintendoAccountApi } from '../../api/NintendoAccountApi';
import HyruleTokenContext from './HyruleTokenContext';
import { NintendoAccountResponse } from '../../api/models';

interface performSearchInterface {
    (text: string, history: History): void;
}

interface clearSearchInterface {
    (history: History): void;
}

export interface SearchContextInterface {
    searchText: string;
    resolvedNaid: string;
    resolvedEmail: string;
    resolvedSerialNumber: string;
    correlationId: string;
    searchFormatError: string;
    isNintendoAccountLoading: boolean;
    isNintendoAccountError: boolean;
    isNintendoAccountNotFound: boolean;
    performSearch: performSearchInterface;
    clearSearch: clearSearchInterface;
}

interface SearchStateInterface {
    naid: string | null;
    email: string | null;
    serialNumber: string | null;
}

export const SearchContext = React.createContext<SearchContextInterface | null>(null);

export const SearchContextProvider: React.FC<React.ReactNode> = ({ children }) => {
    const [searchText, setSearchText] = useState<string>('');
    const [naid, setNaid] = useState<string>('');
    const [email, setEmail] = useState<string>('');
    const [serialNumber, setSerialNumber] = useState<string>('');
    const [correlationId, setCorrelationId] = useState<string>('');
    const [searchFormatError, setSearchFormatError] = useState<string>('');
    const [isNintendoAccountLoading, setIsNintendoAccountLoading] = useState(false);
    const [isNintendoAccountNotFound, setIsNintendoAccountNotFound] = useState(false);
    const [isNintendoAccountError, setIsNintendoAccountError] = useState(false);
    const token: HyruleToken = useContext(HyruleTokenContext);

    const performSearch = (text: string, history: History): void => {
        const newlyCreatedCorrelationIdForThisSearch = uuidv4();
        setCorrelationId(newlyCreatedCorrelationIdForThisSearch);
        setSearchText(text);

        const isValidEmail: boolean = Utils.isValidEmail(text);
        const isValidSerialNumber: boolean = Utils.isValidSerialNumber(text);
        const isValidNAID: boolean = Utils.isValidNAID(text);

        if (isValidEmail || isValidNAID) {
            setSearchFormatError('');
            resolveNaidOrEmail();
        } else if (isValidSerialNumber) {
            setSearchFormatError('');
            resolveSerialNumber();
        } else {
            setSearchFormatError(
                'Your search term is not in a format that is recognized. Please check your search term and try again.',
            );
        }

        function resolveNaidOrEmail(): void {
            const email = isValidEmail ? text : '';
            const naid = isValidNAID ? text : '';

            setEmail(email);
            setNaid(naid);
            setSerialNumber('');

            pushToHistory({ naid, email, serialNumber: '' });

            requestNaidAndEmailFromNintendoAccountApi(email, naid);
        }

        function requestNaidAndEmailFromNintendoAccountApi(email: string, naid: string): void {
            setIsNintendoAccountLoading(true);
            setIsNintendoAccountError(false);
            setIsNintendoAccountNotFound(false);

            NintendoAccountApi.getNaidOrEmail(
                { email, naid },
                token,
                newlyCreatedCorrelationIdForThisSearch,
            )
                .then((nasResponse: NintendoAccountResponse) => {
                    if (token.accessToken && !nasResponse.nasError && !nasResponse.nasNotFound) {
                        setNaid(nasResponse.naid || '');
                        setEmail(nasResponse.email || '');
                    }
                    if (nasResponse.nasNotFound) {
                        setIsNintendoAccountNotFound(true);
                    }
                    if (nasResponse.nasError) {
                        setIsNintendoAccountError(true);
                    }
                })
                .finally(() => {
                    setIsNintendoAccountLoading(false);
                });
        }

        function resolveSerialNumber(): void {
            setSerialNumber(text);
            setEmail('');
            setNaid('');
            pushToHistory({ naid: null, email: null, serialNumber: text });
        }

        function pushToHistory(searchState: SearchStateInterface): void {
            let path = '';
            if (searchState.naid) {
                path = `/account/${searchState.naid}`;
            } else if (searchState.email) {
                path = `/account/${searchState.email}`;
            } else if (searchState.serialNumber) {
                path = `/device/${searchState.serialNumber}`;
            } else {
                throw new Error('Invalid search state object!');
            }

            if (history.location.pathname !== path) history.push(path, searchState);
        }
    };

    const clearSearch = (history: History): void => {
        setSearchText('');
        setNaid('');
        setEmail('');
        setSerialNumber('');
        resetNintendoAccountState();
        history.push('/account');
    };

    function resetNintendoAccountState(): void {
        setIsNintendoAccountLoading(false);
        setIsNintendoAccountError(false);
        setIsNintendoAccountNotFound(false);
    }

    const contextValue = useMemo<SearchContextInterface>(
        () => ({
            searchText,
            resolvedNaid: naid,
            resolvedEmail: email,
            resolvedSerialNumber: serialNumber,
            searchFormatError,
            isNintendoAccountError,
            isNintendoAccountLoading,
            isNintendoAccountNotFound,
            correlationId,
            performSearch,
            clearSearch,
        }),
        [
            searchText,
            naid,
            email,
            serialNumber,
            searchFormatError,
            isNintendoAccountError,
            isNintendoAccountLoading,
            isNintendoAccountNotFound,
            performSearch,
            clearSearch,
        ],
    );

    return <SearchContext.Provider value={contextValue}>{children}</SearchContext.Provider>;
};
