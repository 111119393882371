import {
    HyruleApplication,
    HyruleComponentConfig,
    HyruleToken,
} from '@nintendo/hyrule-react-commons';
import React, { FunctionComponent, useState } from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import Container from 'react-bootstrap/Container';
import Account from './components/account/Account';
import BackToTopButton from './components/shared/components/BackToTopButton';
import AppVersion from './components/shared/components/AppVersion';
import Device from './components/device/Device';
import Header from './components/Header';
import HyruleTokenContext from './components/contexts/HyruleTokenContext';
import { SearchContextProvider } from './components/contexts/SearchContext';

const App: FunctionComponent = () => {
    const [hyruleToken, setHyruleToken] = useState({} as HyruleToken);

    const queryClient = new QueryClient({
        defaultOptions: {
            queries: {
                retry: 1,
                refetchOnWindowFocus: false,
            },
        },
    });

    const routes: HyruleComponentConfig[] = [
        {
            name: 'Account',
            path: '/account/:emailOrNaidParam?',
            component: <Account />,
            scopes: null,
        },
        {
            name: 'Device',
            path: '/device/:serialNumberParam?',
            component: <Device />,
            scopes: null,
        },
    ];

    const authTokenCallback: (token: HyruleToken) => void = (token: HyruleToken) => {
        setHyruleToken(token);
    };

    return (
        <Container className="p-3" fluid>
            <HyruleTokenContext.Provider value={hyruleToken}>
                <QueryClientProvider client={queryClient}>
                    <SearchContextProvider>
                        <HyruleApplication
                            appName="WarpZone"
                            authTokenCallback={authTokenCallback}
                            defaultPath="/account"
                            navHeaderCustom={Header}
                            tabs={[]}
                            routes={routes}
                        />
                    </SearchContextProvider>
                    <BackToTopButton />
                    <AppVersion />
                </QueryClientProvider>
            </HyruleTokenContext.Provider>
        </Container>
    );
};

export default App;
