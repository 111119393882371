import './AssociatedAccounts.scss';
import React, { FunctionComponent, ReactElement, useContext, useState } from 'react';
import { HyruleToken } from '@nintendo/hyrule-react-commons';
import AssociatedAccountTable from './AssociatedAccountTable';
import AssociatedAccountDetails from './AssociatedAccountDetails';
import AssociatedAccountsNoResultsMessage from './AssociatedAccountsNoResultsMessage';
import CollapsiblePane from '../../shared/components/CollapsiblePane';
import HyruleTokenContext from '../../contexts/HyruleTokenContext';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import ServerErrorMessage from '../../shared/components/outline-messages/ServerErrorMessage';
import { SearchContext } from '../../contexts/SearchContext';
import { AssociatedAccountModel } from './AssociatedAccountModel';
import { useAssociatedAccounts } from './useAssociatedAccounts';

const AssociatedAccounts: FunctionComponent = () => {
    const searchContext = useContext(SearchContext);
    const token: HyruleToken = useContext(HyruleTokenContext);
    const serialNumber = searchContext?.resolvedSerialNumber || '';
    const correlationId = searchContext?.correlationId || '';
    const [selectedAccountId, setSelectedAccountId] = useState<string>('');
    const {
        data: accounts,
        loading,
        notFound,
        error,
    } = useAssociatedAccounts(serialNumber, token, correlationId);

    const resolveSelectedAccountId = (): string => {
        const validSelectedAccount = accounts.find(
            (a) => a.nintendoAccountId === selectedAccountId,
        );
        if (validSelectedAccount) return validSelectedAccount.nintendoAccountId;

        return accounts.length > 0 ? accounts[0].nintendoAccountId : '';
    };

    const selectedAccount: AssociatedAccountModel =
        accounts.find((account) => account.nintendoAccountId === resolveSelectedAccountId()) ||
        ({} as AssociatedAccountModel);

    let paneContent: ReactElement = (
        <div className="associated-accounts row">
            <div className="table col-6 col-sm-3">
                <AssociatedAccountTable
                    accounts={accounts}
                    onAccountSelect={setSelectedAccountId}
                    selectedAccountId={resolveSelectedAccountId()}
                />
            </div>
            <div className="col-6 col-sm-9">
                <AssociatedAccountDetails
                    accountBanStatus={selectedAccount.accountBanStatus}
                    email={selectedAccount.email}
                    nickname={selectedAccount.nickname}
                    nsaNickname={selectedAccount.nsaNickname}
                    nsoStatus={selectedAccount.nsoStatus}
                    nintendoAccountId={selectedAccount.nintendoAccountId}
                />
            </div>
        </div>
    );

    if (notFound) {
        paneContent = <AssociatedAccountsNoResultsMessage />;
    }

    if (error) {
        paneContent = <ServerErrorMessage />;
    }

    const loadingSpinner: ReactElement = (
        <div className="text-center">
            <LoadingSpinner />
        </div>
    );

    return (
        <CollapsiblePane eventKey="AssociatedAccounts" headerText="Associated Nintendo Accounts">
            {loading && loadingSpinner}
            {!loading && paneContent}
        </CollapsiblePane>
    );
};

export default AssociatedAccounts;
