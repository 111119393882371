import { useEffect, useState } from 'react';
import { HyruleToken } from '@nintendo/hyrule-react-commons';
import dayjs from 'dayjs';
import { AccountDetailsInformation } from '../account-details/AccountDetailsInformation';
import { AccountDeviceDetailsTableEntryModel } from '../account-device-details/AccountDeviceDetailsTable';
import { AccountByNaidApiModel } from '../../../api/models';
import { AccountApi } from '../../../api';
import Utils from '../../../shared/Utils';
import { AccountByNaidModel } from './types';

const DEFAULT_DATA: AccountByNaidModel = {
    account: {} as AccountDetailsInformation,
    devices: [] as AccountDeviceDetailsTableEntryModel[],
    lastUpdated: null,
};

interface AccountHookData {
    loading: boolean;
    data: AccountByNaidModel;
}

export const useAccount = (
    naid: string,
    email: string,
    token: HyruleToken,
    correlationId: string,
): AccountHookData => {
    const [data, setData] = useState<AccountByNaidModel>({ ...DEFAULT_DATA });
    const [loading, setLoading] = useState<boolean>(false);

    const getAccountAndDeviceDetails = async (): Promise<void> => {
        setLoading(true);

        const accountByNaidAPIModel: AccountByNaidApiModel =
            await AccountApi.getAccountInformationByNAID(naid, token, correlationId);

        const accountDetails: AccountDetailsInformation = AccountApi.mapToAccountDetailsModel(
            accountByNaidAPIModel.account,
            naid,
            email,
        );

        const accountDeviceDetails: AccountDeviceDetailsTableEntryModel[] =
            AccountApi.mapToAccountDeviceDetailsTableEntryModel(accountByNaidAPIModel.devices);

        setLoading(false);

        setData({
            account: accountDetails,
            devices: accountDeviceDetails,
            lastUpdated: dayjs(accountByNaidAPIModel.last_updated),
        });
    };

    useEffect(() => {
        if (Utils.isValidNAID(naid) && token.accessToken) {
            getAccountAndDeviceDetails();
        } else {
            setData({ ...DEFAULT_DATA });
        }
    }, [naid, email, token]);

    return { loading, data };
};
