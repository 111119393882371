import React, { FunctionComponent } from 'react';
import StatusScreen from './StatusScreen';
import Footer from '../../../Footer';
import LoadingSpinner from '../LoadingSpinner';

const LoadingScreen: FunctionComponent = () => (
    <>
        <StatusScreen
            statusImage={<LoadingSpinner />}
            statusTitle="Searching for matching Email/NAID..."
            dataCy="nas-blank-state-component"
        />
        <Footer />
    </>
);

export default LoadingScreen;
