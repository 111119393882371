import { HyruleToken } from '@nintendo/hyrule-react-commons';
import { useEffect, useState } from 'react';
import Utils from '../../../shared/Utils';
import { DeviceApi } from '../../../api';
import { RepairModel } from '../../../types/RepairModel';

interface RepairsByEmailHookData {
    data: RepairModel[];
    loading: boolean;
    notFound: boolean;
    error: boolean;
}

export const useRepairsByEmail = (
    email: string,
    token: HyruleToken,
    correlationId: string,
): RepairsByEmailHookData => {
    const [data, setData] = useState<RepairModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [notFound, setNotFound] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const getRepairData = async (): Promise<void> => {
        setLoading(true);

        const { notFound, serverError, model } = await DeviceApi.getDeviceRepairsByEmail(
            email,
            token,
            correlationId,
        );
        const repairs: RepairModel[] = DeviceApi.mapToRepairModels(model.repairs);

        setNotFound(notFound);
        setError(serverError);
        setLoading(false);

        setData(repairs);
    };

    useEffect(() => {
        if (Utils.isValidEmail(email) && token.accessToken) {
            getRepairData();
        } else {
            setData([]);
            setNotFound(true);
        }
    }, [email, token]);

    return {
        data,
        loading,
        notFound,
        error,
    };
};
