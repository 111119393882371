import React, { FunctionComponent } from 'react';
import StatusScreen from './StatusScreen';
import { ReactComponent as BooColoredImage } from '../../../../assets/boo-colored.svg';
import Footer from '../../../Footer';

const ServerErrorScreen: FunctionComponent = () => (
    <>
        <StatusScreen
            statusImage={<BooColoredImage name="boo-colored-image" />}
            statusTitle="Uh Oh!"
            statusMessage="Sorry, we are currently having trouble retrieving information."
            dataCy="nas-search-server-error-component"
        />
        <Footer />
    </>
);

export default ServerErrorScreen;
