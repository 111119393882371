import './AccountDetails.scss';
import { Col, Row, Table } from 'react-bootstrap';
import React, { FunctionComponent, ReactElement, useContext } from 'react';
import dayjs from 'dayjs';
import CollapsiblePane from '../../shared/components/CollapsiblePane';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import Utils from '../../../shared/Utils';
import pitIcon from '../../../assets/pit_icon.svg';
import opeLogo from '../../../assets/logo_ope.svg';
import { CopyToClipboardWithLogoButtonProps } from '../../shared/components/CopyToClipboardWithLogoButton';
import { SearchContext } from '../../contexts/SearchContext';
import { AccountDetailsInformation } from './AccountDetailsInformation';
import { DateFormat } from '../../../shared/DateFormat';
import { LastUpdated } from '../../shared/components/last-updated/LastUpdated';

export interface AccountDetailsProps {
    account: AccountDetailsInformation;
    isLoading: boolean;
    lastUpdated: dayjs.Dayjs | null;
}

interface AccountDetailPairModel {
    label: string;
    value: string;
}

const AccountDetailPairLabel: FunctionComponent<AccountDetailPairModel> = ({ label }) => (
    <Col xs={3} sm={6} className="font-weight-bold mb-2">
        {label}:
    </Col>
);

const AccountDetailPairValue: FunctionComponent<AccountDetailPairModel> = ({ label, value }) => (
    <Col
        xs={3}
        sm={6}
        className={`mb-2 ${value ? '' : ' font-italic'}`}
        account-detail-value-for={label}
    >
        {value || 'Unavailable'}
    </Col>
);

const AccountDetailPair: FunctionComponent<AccountDetailPairModel> = (model) => (
    <>
        {AccountDetailPairLabel(model)}
        {AccountDetailPairValue(model)}
    </>
);

const AccountDetails: FunctionComponent<AccountDetailsProps> = ({
    account,
    lastUpdated,
    isLoading,
}): ReactElement => {
    const naid = useContext(SearchContext)?.resolvedNaid;

    const {
        accountId,
        accountNickname,
        autoRenewal,
        banStatus,
        email,
        nsaNickname,
        nsoStatus,
        planExpiration,
    } = account;

    const exp: string = Utils.expirationDate(planExpiration, DateFormat.dateWithTime);
    const membershipTable: ReactElement = (
        <Table bordered data-cy="nso-membership-table">
            <thead>
                <tr>
                    <th className="membership-table" colSpan={3}>
                        Nintendo Switch Online Membership
                    </th>
                </tr>
                <tr className="membership-headers">
                    <th>NSO Status</th>
                    <th>Expiration Date</th>
                    <th>Auto-Renewal</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td className={nsoStatus ? '' : 'font-italic'}>{nsoStatus || 'None'}</td>
                    <td className={exp ? '' : 'font-italic'}>{exp || 'None'}</td>
                    <td className={autoRenewal ? '' : 'font-italic'}>{autoRenewal || 'None'}</td>
                </tr>
            </tbody>
        </Table>
    );

    // Display data in one way in "small mode".
    // See grid breakpoints in CustomBootstrap.scss for more details.
    const smallModeContent: ReactElement = (
        <Row noGutters className="hide-in-normal-mode">
            <AccountDetailPair label="Email Address" value={email} />
            <AccountDetailPair label="NA Nickname" value={accountNickname} />
            <AccountDetailPair label="Nintendo Account ID" value={accountId} />
            <AccountDetailPair label="NA Ban Status" value={banStatus} />
            <AccountDetailPair label="NSA Nickname" value={nsaNickname} />
        </Row>
    );

    // Display data in another way in "normal mode".
    // See grid breakpoints in CustomBootstrap.scss for more details.
    const normalModeContent: ReactElement = (
        <Row noGutters className="hide-in-small-mode">
            <AccountDetailPair label="Email Address" value={email} />
            <AccountDetailPair label="Nintendo Account ID" value={accountId} />
            <AccountDetailPair label="NSA Nickname" value={nsaNickname} />
            <AccountDetailPair label="NA Nickname" value={accountNickname} />
            <AccountDetailPair label="NA Ban Status" value={banStatus} />
        </Row>
    );

    const paneContent: ReactElement = (
        <Row>
            <Col sm={6}>
                {smallModeContent}
                {normalModeContent}
            </Col>
            <Col sm={6} className="mt-3 mt-sm-0">
                {membershipTable}
            </Col>
        </Row>
    );

    return (
        <CollapsiblePane
            eventKey="AccountDetails"
            headerText="Nintendo Account Details"
            dataCyForBody="nintendo-account-details"
            copyToClipboardButtons={naid ? makeCopyToClipboardButtons(naid) : []}
            additionalHeaderElement={
                lastUpdated ? <LastUpdated dateTime={lastUpdated} /> : undefined
            }
        >
            {isLoading && (
                <div className="text-center">
                    <LoadingSpinner />
                </div>
            )}
            {!isLoading && paneContent}
        </CollapsiblePane>
    );
};

const makeCopyToClipboardButtons = (naid: string): CopyToClipboardWithLogoButtonProps[] => [
    {
        id: 'pit-button',
        imgSrc: pitIcon,
        imgAlt: 'Copy link to PIT to clipboard',
        textToCopy: `https://pit.mng.nintendo.net/pit/account/eShopInfo/${naid}`,
    },
    {
        id: 'ope-button',
        imgSrc: opeLogo,
        imgAlt: 'Copy link to OPE to clipboard',
        textToCopy: `https://ops.baas.nintendo.com/#/nintendo_account/users/${naid}`,
    },
];

export default AccountDetails;
