import { useEffect, useState } from 'react';
import { HyruleToken } from '@nintendo/hyrule-react-commons';
import { GameRecommendationEntryModel } from './GameRecommendationEntryModel';
import Utils from '../../../shared/Utils';
import { GameRecommendationApiModel } from '../../../api/models';
import { GameRecommendationsApi } from '../../../api';

interface GameRecommendationsHookData {
    data: GameRecommendationEntryModel[];
    loading: boolean;
    error: boolean;
}

export const useGameRecommendations = (
    naid: string,
    token: HyruleToken,
    correlationId: string,
): GameRecommendationsHookData => {
    const [data, setData] = useState<GameRecommendationEntryModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const getGameRecommendations = async (): Promise<void> => {
        setLoading(true);
        try {
            const gameRecommendationApiModel: GameRecommendationApiModel =
                await GameRecommendationsApi.getGameRecommendations(naid, token, correlationId);
            setData(
                GameRecommendationsApi.mapToGameRecommendationEntryModels(
                    gameRecommendationApiModel,
                ),
            );
            setError(false);
        } catch (error) {
            setData([]);
            setError(true);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (Utils.isValidNAID(naid) && token.accessToken) getGameRecommendations();
        else setData([]);
    }, [naid, token]);

    return {
        data,
        loading,
        error,
    };
};
