import React, { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';
import Utils from '../../../shared/Utils';
import { AccountDetailPair } from './AccountDetailPair';
import { AssociatedAccountModel } from './AssociatedAccountModel';

type AssociatedAccountDetailsProps = Omit<AssociatedAccountModel, 'accountNumber' | 'priority'>;

const AssociatedAccountDetails: FunctionComponent<AssociatedAccountDetailsProps> = ({
    nintendoAccountId,
    accountBanStatus,
    email,
    nickname,
    nsaNickname,
    nsoStatus,
}) => {
    const accountLink = `/account/${nintendoAccountId}`;
    const banStatusContent: string = accountBanStatus || 'Unavailable';
    const emailContent: string = Utils.isValidEmail(email) ? email : 'Unavailable';
    const nicknameContent: string = nickname || 'Unavailable';
    const nsaNicknameContent: string = nsaNickname || 'Unavailable';
    const nsoStatusContent: string = nsoStatus || 'None';

    return (
        <div className="associated-account-details row">
            <AccountDetailPair label="Email Address" content={emailContent} testId="email" />
            <div className="col-5 col-sm-3 font-weight-bold">NA ID:</div>
            <div className="col-7 col-sm-3" data-cy="naid-link-container">
                {nintendoAccountId && <Link to={accountLink}>{nintendoAccountId}</Link>}
            </div>
            <AccountDetailPair
                label="NSA Nickname"
                content={nsaNicknameContent}
                testId="nsa-nickname"
            />
            <AccountDetailPair label="NA Nickname" content={nicknameContent} testId="nick-name" />
            <AccountDetailPair label="NSO Status" content={nsoStatusContent} testId="nso-status" />
            <AccountDetailPair
                label="NA Ban Status"
                content={banStatusContent}
                testId="ban-status"
            />
        </div>
    );
};

export default AssociatedAccountDetails;
