import React, { ChangeEvent, FunctionComponent } from 'react';
import './Pagination.scss';

interface PaginationProps {
    pagination: PaginationInterface;
    updatePagination: (pagination: PaginationInterface) => void;
}

export interface PaginationInterface {
    totalPages: number;
    currentPage: number;
    pageSize: number;
    numItems: number;
}

export const Pagination: FunctionComponent<PaginationProps> = ({
    pagination,
    updatePagination,
}) => {
    const updatePageSize = (event: ChangeEvent<HTMLSelectElement>) => {
        const newPageSize = Number(event.target.value);
        const newTotalPages = Math.ceil(pagination.numItems / newPageSize);
        updatePagination({
            ...pagination,
            pageSize: newPageSize,
            totalPages: newTotalPages,
            currentPage:
                pagination.currentPage > newTotalPages ? newTotalPages : pagination.currentPage,
        });
    };
    return (
        <div className="wz-pagination">
            <button
                type="button"
                className="wz-pagination__page-button btn btn-sm btn-secondary mr-auto"
                disabled={pagination.currentPage <= 1}
                onClick={() =>
                    updatePagination({
                        ...pagination,
                        currentPage: Math.max(pagination.currentPage - 1, 1),
                    })
                }
            >
                Previous Page
            </button>
            <div>
                <span>
                    {pagination.currentPage} / {pagination.totalPages}
                </span>
                <span className="ml-5">
                    <label className="pagination__page-size-label" htmlFor="orders-page-size">
                        Page Size:
                    </label>
                    <select
                        id="orders-page-size"
                        className="wz-pagination__page-size-select ml-2 p-1"
                        onChange={(event) => updatePageSize(event)}
                    >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="100">100</option>
                    </select>
                </span>
            </div>
            <button
                type="button"
                className="wz-pagination__page-button btn btn-sm btn-secondary ml-auto"
                disabled={pagination.currentPage >= pagination.totalPages}
                onClick={() =>
                    updatePagination({
                        ...pagination,
                        currentPage: Math.min(pagination.currentPage + 1, pagination.totalPages),
                    })
                }
            >
                Next Page
            </button>
        </div>
    );
};
