import React, { FunctionComponent, useContext, useEffect } from 'react';
// eslint-disable-next-line import/no-unresolved
import { hasScope } from '@nintendo/hyrule-react-commons/lib/js/util/AuthUtils';
import { useHistory, useParams } from 'react-router-dom';
import AssociatedAccounts from './associated-accounts/AssociatedAccounts';
import DeviceDetails from './device-details/DeviceDetails';
import ErrorCodes from './error-codes/ErrorCodes';
import Footer from '../Footer';
import NoAccess from '../shared/components/auth/NoAccess';
import Repairs from './repairs/Repairs';
import { validDiceScopes } from '../../shared/ScopeUtils';
import { SearchContext } from '../contexts/SearchContext';
import HyruleTokenContext from '../contexts/HyruleTokenContext';
import BlankStateScreen from '../shared/components/nas-screens/BlankStateScreen';
import Utils from '../../shared/Utils';

const Device: FunctionComponent = () => {
    const searchContext = useContext(SearchContext);
    const hyruleToken = useContext(HyruleTokenContext);
    const history = useHistory();
    const serialNumber = searchContext?.resolvedSerialNumber || '';

    const { serialNumberParam } = useParams<{ serialNumberParam: string }>();

    const isParamFromADirectLinkThatShouldPerformASearch: boolean =
        !serialNumber && !!serialNumberParam && Utils.isValidSerialNumber(serialNumberParam);

    useEffect(() => {
        if (isParamFromADirectLinkThatShouldPerformASearch && hyruleToken.accessToken) {
            searchContext?.performSearch(serialNumberParam, history);
        }
    }, [serialNumber, serialNumberParam, hyruleToken.accessToken]);

    if (!hasScope(validDiceScopes)) {
        return <NoAccess />;
    }

    if (!serialNumber) {
        return <BlankStateScreen />;
    }

    return (
        <>
            <DeviceDetails />
            <AssociatedAccounts />
            <Repairs />
            <ErrorCodes />
            <Footer />
        </>
    );
};

export default Device;
