import React, { useState, useRef, FunctionComponent } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/arrow-up-right-from-square.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/xmark.svg';
import { ShippingLabel } from '../../../../api/models';
import { CopyButton } from '../../../../shared/CopyButton';
import './TrackingInfoOverlay.scss';

interface TrackingInfoOverlayProps {
    orderNumber: string;
    inboundLabels: ShippingLabel[];
    outboundLabels: ShippingLabel[];
    children: JSX.Element;
}

const TrackingInfoOverlay: FunctionComponent<TrackingInfoOverlayProps> = ({
    orderNumber,
    inboundLabels,
    outboundLabels,
    children,
}) => {
    const target = useRef(null);
    const [show, setShow] = useState<boolean>(false);
    const mostRecentInboundLabel = inboundLabels[inboundLabels.length - 1];
    const mostRecentOutboundLabel = outboundLabels[outboundLabels.length - 1];

    return (
        <>
            <Overlay
                target={target.current}
                rootClose
                placement="right-start"
                show={show}
                onHide={() => {
                    setShow(false);
                }}
            >
                <div className="tracking-info-overlay">
                    <Popover id="repair-order">
                        <Popover.Title as="h3">
                            <div className="tracking-info-overlay__header">
                                <div>Repair Order Information</div>
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={() => setShow(false)}
                                >
                                    <CloseIcon className="tracking-info-overlay__close-icon" />
                                </button>
                            </div>
                        </Popover.Title>
                        <Popover.Content>
                            <dl className="tracking-info-overlay__grid">
                                <dt className="tracking-info-overlay__label">Repair Order #:</dt>
                                <dd className="tracking-info-overlay__info">
                                    <div className="tracking-info-overlay__row">
                                        <a
                                            href="https://repair.nintendo.com/saml/signin"
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {orderNumber}
                                        </a>
                                        <div className="tracking-info-overlay__copy-icon">
                                            {orderNumber && (
                                                <CopyButton
                                                    data-testid="copy-order-number"
                                                    textToCopy={orderNumber}
                                                    title={`Copy ${orderNumber} to clipboard.`}
                                                    id={`repair-copy-${orderNumber}`}
                                                />
                                            )}
                                        </div>
                                        <ArrowRightIcon className="tracking-info-overlay__arrow-icon" />
                                    </div>
                                </dd>
                                <dt className="tracking-info-overlay__label">
                                    Inbound Tracking #:
                                </dt>
                                {mostRecentInboundLabel?.tracking_No ? (
                                    <dd className="tracking-info-overlay__info">
                                        <a
                                            href={`http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${mostRecentInboundLabel.tracking_No}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {mostRecentInboundLabel.tracking_No}
                                        </a>
                                        <ArrowRightIcon className="tracking-info-overlay__arrow-icon" />
                                    </dd>
                                ) : (
                                    <dd className="tracking-info-overlay__info-unavailable">
                                        Not Available
                                    </dd>
                                )}
                                <dt className="tracking-info-overlay__label">
                                    Outbound Tracking #:
                                </dt>
                                {mostRecentOutboundLabel?.tracking_No ? (
                                    <dd className="tracking-info-overlay__info">
                                        <a
                                            href={`http://wwwapps.ups.com/WebTracking/track?track=yes&trackNums=${mostRecentOutboundLabel.tracking_No}`}
                                            target="_blank"
                                            rel="noreferrer"
                                        >
                                            {mostRecentOutboundLabel.tracking_No}
                                        </a>
                                        <ArrowRightIcon className="tracking-info-overlay__arrow-icon" />
                                    </dd>
                                ) : (
                                    <dd className="tracking-info-overlay__info-unavailable">
                                        Not Available
                                    </dd>
                                )}
                            </dl>
                        </Popover.Content>
                    </Popover>
                </div>
            </Overlay>

            <button
                data-testid="tracking-popover"
                type="button"
                className="btn btn-link repairs-table-popover-trigger"
                ref={target}
                onClick={() => setShow(true)}
            >
                {children}
            </button>
        </>
    );
};

export default TrackingInfoOverlay;
