import { Link } from 'react-router-dom';
import React, { FunctionComponent, ReactElement } from 'react';
import DiceTable, { DiceTableRowInterface } from '../../shared/components/DiceTable';
import { DeviceType } from '../../shared/models/SharedEnums';

export interface AccountDeviceDetailsTableEntryModel {
    serialNumber: string;
    type: DeviceType;
    warrantyStatus: string;
    deviceViewUrl: string;
}

const serialNumberColumn = (model: AccountDeviceDetailsTableEntryModel): ReactElement => (
    <Link to={model.deviceViewUrl}>{model.serialNumber}</Link>
);

const typeColumn = (model: AccountDeviceDetailsTableEntryModel): ReactElement => (
    <span>{model.type}</span>
);

const warrantyStatusColumn = (model: AccountDeviceDetailsTableEntryModel): ReactElement => (
    <span className={model.warrantyStatus || 'font-italic'}>
        {model.warrantyStatus || 'Unavailable'}
    </span>
);

const deviceViewColumn = (model: AccountDeviceDetailsTableEntryModel): ReactElement => (
    <Link to={model.deviceViewUrl}>Go to Device View</Link>
);

const AccountDeviceDetailsTable: FunctionComponent<{
    entries: AccountDeviceDetailsTableEntryModel[];
}> = ({ entries }) => {
    const rows: DiceTableRowInterface[] = entries.map((accountDeviceDetailsEntry) => ({
        columns: [
            serialNumberColumn(accountDeviceDetailsEntry),
            typeColumn(accountDeviceDetailsEntry),
            warrantyStatusColumn(accountDeviceDetailsEntry),
            deviceViewColumn(accountDeviceDetailsEntry),
        ],
        columnsToHideInSmallMode: [3],
    }));

    return (
        <DiceTable
            tableName="account-device-details"
            headerNames={['Serial Number', 'Type', 'Warranty Status', 'Device View']}
            headersToHideInSmallMode={[3]}
            rows={rows}
        />
    );
};

export default AccountDeviceDetailsTable;
