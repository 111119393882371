import { AccountApiUrls, ApiConfig, DeviceApiUrls, EShopApiUrls } from './types';

const testDiceApiBaseUrl = 'https://warpzone-api-test.noa.nintendo.com';
const testNasApiUrl = `${testDiceApiBaseUrl}/emailornaid`;
const testDiceMulesoftProxyUrl = `${testDiceApiBaseUrl}/mulesoft-proxy`;

const testAccountApiUrls: AccountApiUrls = {
    accountAndDeviceDetails: (naid: string) => `${testDiceMulesoftProxyUrl}/account/${naid}`,
    gameRecommendations: (naid: string) =>
        `${testDiceMulesoftProxyUrl}/account/recommendations/${naid}`,
    gameSummaries: (naid: string) =>
        `${testDiceMulesoftProxyUrl}/v1_1/${naid}/gameplay/summary?region_id=2`,
    gameSummaryDetails: (naid: string, applicationId: string) =>
        `${testDiceMulesoftProxyUrl}/v1_1/${naid}/gameplay/summary/${applicationId}/details?region_id=2`,
    partOrdersByNaid: (naid: string) =>
        `${testDiceMulesoftProxyUrl}/v1_1/account/part_orders?naid=${naid}&sort_order=desc&limit=3`,
    partOrdersByEmail: (email: string) =>
        `${testDiceMulesoftProxyUrl}/v1_1/account/part_orders?email=${email}&sort_order=desc&limit=3`,
    partOrdersByEmailAndNaid: (email: string, naid: string) =>
        `${testDiceMulesoftProxyUrl}/v1_1/account/part_orders?email=${email}&naid=${naid}&sort_order=desc&limit=3`,
};

const testDeviceApiUrls: DeviceApiUrls = {
    associatedAccounts: (serialNumber: string) =>
        `${testDiceMulesoftProxyUrl}/v1_1/device/accounts/${serialNumber}`,
    deviceDetails: (serialNumber: string) =>
        `${testDiceMulesoftProxyUrl}/v1_1/device/${serialNumber}`,
    deviceRepairs: (serialNumber: string) =>
        `${testDiceMulesoftProxyUrl}/v1_1/device/repairs?serial_number=${serialNumber}&sort_order=desc&limit=3`,
    deviceRepairsByEmail: (email: string) =>
        `${testDiceMulesoftProxyUrl}/v1_1/device/repairs?email=${email}&sort_order=desc&limit=3`,
    errorCodes: () => `${testDiceMulesoftProxyUrl}/v1_1/device/error_codes`,
};

const testEShopApiUrls: EShopApiUrls = {
    purchaseHistory: (naid: string) => `${testDiceApiBaseUrl}/account/${naid}/purchase-history`,
};

export const testApiConfig: ApiConfig = {
    accountApiUrls: testAccountApiUrls,
    deviceApiUrls: testDeviceApiUrls,
    eShopApiUrls: testEShopApiUrls,
    nasUrl: testNasApiUrl,
};
