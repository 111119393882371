import React, { FunctionComponent } from 'react';
import OutlineMessage from './OutlineMessage';
import { ReactComponent as BooOutlineImage } from '../../../../assets/boo-outline.svg';

const ServerErrorMessage: FunctionComponent = () => (
    <OutlineMessage
        message="Sorry, we are currently having trouble retrieving this information."
        messageLocation="message-right"
        outlineImage={<BooOutlineImage name="boo-outline-image" />}
    />
);

export default ServerErrorMessage;
