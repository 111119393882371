import React, { FunctionComponent, ReactElement } from 'react';
import CollapsiblePane from '../../shared/components/CollapsiblePane';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import ServerErrorMessage from '../../shared/components/outline-messages/ServerErrorMessage';
import OrdersTable from './OrdersTable';
import { ReactComponent as ShellOutlineImage } from '../../../assets/shell-outline.svg';
import OutlineMessage from '../../shared/components/outline-messages/OutlineMessage';
import { OrderModel } from './types';

export interface OrdersProps {
    orders: OrderModel[];
    isError: boolean;
    isNotFound: boolean;
    isLoading: boolean;
}

const Orders: FunctionComponent<OrdersProps> = ({
    orders,
    isLoading,
    isError,
    isNotFound,
}): ReactElement => {
    let paneContent: ReactElement = <OrdersTable orders={orders} />;

    if (isLoading) {
        paneContent = (
            <div className="text-center">
                <LoadingSpinner />
            </div>
        );
    }

    if (isError) {
        paneContent = <ServerErrorMessage />;
    }

    if (isNotFound && !isLoading) {
        paneContent = (
            <OutlineMessage
                message="There currently isn't any Order information associated with this Nintendo Account."
                outlineImage={<ShellOutlineImage name="shell-outline-image" />}
                messageLocation="message-right"
            />
        );
    }

    return (
        <CollapsiblePane
            eventKey="Orders"
            headerText="Orders"
            disablePaneContentPadding={!isLoading}
            dataCyForBody="orders"
        >
            {paneContent}
        </CollapsiblePane>
    );
};

export default Orders;
