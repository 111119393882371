import { OrderSubType } from '../../account/orders/types';

export const getOrderHrefLink = (subType: OrderSubType, orderNumber: string) => {
    const hrefMap = {
        [OrderSubType.ServiceOrderStoreMagento]:
            'https://serviceordersadmin.noa.nintendo.com/admin/sales/order',
        [OrderSubType.MyNintendoStorePhysical]: 'https://store.nintendo.com/nse090919/salesgrid',
        [OrderSubType.ESHOP]: `https://pit.mng.nintendo.net/pit/transaction/search?eciTransactionId=${orderNumber}`,
    };

    return hrefMap[subType];
};
