import { AccountApiUrls, ApiConfig, DeviceApiUrls, EShopApiUrls } from './types';

const devDiceApiBaseUrl: string =
    process.env.REACT_APP_DEV_API_BASE_URL || 'https://warpzone-api-dev.noa.nintendo.com';
const devNasApiUrl = `${devDiceApiBaseUrl}/emailornaid`;
const devDiceMulesoftProxyUrl = `${devDiceApiBaseUrl}/mulesoft-proxy`;

const devAccountApiUrls: AccountApiUrls = {
    accountAndDeviceDetails: (naid: string) => `${devDiceMulesoftProxyUrl}/account/${naid}`,
    gameRecommendations: (naid: string) =>
        `${devDiceMulesoftProxyUrl}/account/recommendations/${naid}`,
    gameSummaries: (naid: string) =>
        `${devDiceMulesoftProxyUrl}/v1_1/${naid}/gameplay/summary?region_id=2`,
    gameSummaryDetails: (naid: string, applicationId: string) =>
        `${devDiceMulesoftProxyUrl}/v1_1/${naid}/gameplay/summary/${applicationId}/details?region_id=2`,
    partOrdersByNaid: (naid: string) =>
        `${devDiceMulesoftProxyUrl}/v1_1/account/part_orders?naid=${naid}&sort_order=desc&limit=3`,
    partOrdersByEmail: (email: string) =>
        `${devDiceMulesoftProxyUrl}/v1_1/account/part_orders?email=${email}&sort_order=desc&limit=3`,
    partOrdersByEmailAndNaid: (email: string, naid: string) =>
        `${devDiceMulesoftProxyUrl}/v1_1/account/part_orders?email=${email}&naid=${naid}&sort_order=desc&limit=3`,
};

const devDeviceApiUrls: DeviceApiUrls = {
    associatedAccounts: (serialNumber: string) =>
        `${devDiceMulesoftProxyUrl}/v1_1/device/accounts/${serialNumber}`,
    deviceDetails: (serialNumber: string) =>
        `${devDiceMulesoftProxyUrl}/v1_1/device/${serialNumber}`,
    deviceRepairs: (serialNumber: string) =>
        `${devDiceMulesoftProxyUrl}/v1_1/device/repairs?serial_number=${serialNumber}&sort_order=desc&limit=3`,
    deviceRepairsByEmail: (email: string) =>
        `${devDiceMulesoftProxyUrl}/v1_1/device/repairs?email=${email}&sort_order=desc&limit=3`,
    errorCodes: () => `${devDiceMulesoftProxyUrl}/v1_1/device/error_codes`,
};

const devEShopApiUrls: EShopApiUrls = {
    purchaseHistory: (naid: string) => `${devDiceApiBaseUrl}/account/${naid}/purchase-history`,
};

export const devApiConfig: ApiConfig = {
    accountApiUrls: devAccountApiUrls,
    deviceApiUrls: devDeviceApiUrls,
    eShopApiUrls: devEShopApiUrls,
    nasUrl: devNasApiUrl,
};
