import { AccountApiUrls, ApiConfig, DeviceApiUrls, EShopApiUrls } from './types';

const prodDiceApiBaseUrl = 'https://warpzone-api.noa.nintendo.com';
const prodNasApiUrl = `${prodDiceApiBaseUrl}/emailornaid`;
const prodDiceMulesoftProxyUrl = `${prodDiceApiBaseUrl}/mulesoft-proxy`;

const prodAccountApiUrls: AccountApiUrls = {
    accountAndDeviceDetails: (naid: string) => `${prodDiceMulesoftProxyUrl}/account/${naid}`,
    gameRecommendations: (naid: string) =>
        `${prodDiceMulesoftProxyUrl}/account/recommendations/${naid}`,
    gameSummaries: (naid: string) =>
        `${prodDiceMulesoftProxyUrl}/v1_1/${naid}/gameplay/summary?region_id=2`,
    gameSummaryDetails: (naid: string, applicationId: string) =>
        `${prodDiceMulesoftProxyUrl}/v1_1/${naid}/gameplay/summary/${applicationId}/details?region_id=2`,
    partOrdersByNaid: (naid: string) =>
        `${prodDiceMulesoftProxyUrl}/v1_1/account/part_orders?naid=${naid}&sort_order=desc&limit=3`,
    partOrdersByEmail: (email: string) =>
        `${prodDiceMulesoftProxyUrl}/v1_1/account/part_orders?email=${email}&sort_order=desc&limit=3`,
    partOrdersByEmailAndNaid: (email: string, naid: string) =>
        `${prodDiceMulesoftProxyUrl}/v1_1/account/part_orders?email=${email}&naid=${naid}&sort_order=desc&limit=3`,
};

const prodDeviceApiUrls: DeviceApiUrls = {
    associatedAccounts: (serialNumber: string) =>
        `${prodDiceMulesoftProxyUrl}/v1_1/device/accounts/${serialNumber}`,
    deviceDetails: (serialNumber: string) =>
        `${prodDiceMulesoftProxyUrl}/v1_1/device/${serialNumber}`,
    deviceRepairs: (serialNumber: string) =>
        `${prodDiceMulesoftProxyUrl}/v1_1/device/repairs?serial_number=${serialNumber}&sort_order=desc&limit=3`,
    deviceRepairsByEmail: (email: string) =>
        `${prodDiceMulesoftProxyUrl}/v1_1/device/repairs?email=${email}&sort_order=desc&limit=3`,
    errorCodes: () => `${prodDiceMulesoftProxyUrl}/v1_1/device/error_codes`,
};

const prodEShopApiUrls: EShopApiUrls = {
    purchaseHistory: (naid: string) => `${prodDiceApiBaseUrl}/account/${naid}/purchase-history`,
};

export const prodApiConfig: ApiConfig = {
    accountApiUrls: prodAccountApiUrls,
    deviceApiUrls: prodDeviceApiUrls,
    eShopApiUrls: prodEShopApiUrls,
    nasUrl: prodNasApiUrl,
};
