import './HeaderSearchFields.scss';
import React, { FormEvent, FunctionComponent, useContext, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { useHistory } from 'react-router-dom';
import { ReactComponent as ClearSearchIcon } from '../assets/clear-search.svg';
import { ReactComponent as SearchIcon } from '../assets/search_icon.svg';
import { SearchContext } from './contexts/SearchContext';

const HeaderSearchFields: FunctionComponent = () => {
    const searchContext = useContext(SearchContext);
    const history = useHistory();
    const [inputText, setInputText] = useState<string>(searchContext?.searchText || '');

    const clearSearch = (): void => {
        setInputText('');
        searchContext?.clearSearch(history);
    };

    useEffect(() => {
        setInputText(searchContext?.searchText || '');
    }, [searchContext?.searchText]);

    const clearSearchButton = (
        <div className="d-flex">
            <button
                className="button-reset link"
                type="button"
                onClick={(): void => clearSearch()}
                data-cy="clear-search-button"
            >
                <span className="mr-1">
                    <ClearSearchIcon />
                </span>
                Clear Search Result
            </button>
        </div>
    );

    const onFormSubmit = (event: FormEvent): void => {
        event.preventDefault();
        const formData = new FormData(event.currentTarget as HTMLFormElement);
        const enteredSearchText = (formData.get('search') || '').toString();
        if (enteredSearchText) searchContext?.performSearch(enteredSearchText.trim(), history);
    };

    return (
        <form onSubmit={onFormSubmit}>
            <div className="header-search d-flex align-items-center">
                <input
                    className={`header-search__search-text-box form-control form-control-sm ${
                        searchContext?.searchFormatError && inputText === searchContext?.searchText
                            ? 'is-invalid'
                            : ''
                    }`}
                    placeholder="Email Address, NAID or Serial Number"
                    name="search"
                    value={inputText}
                    onChange={(event): void => setInputText(event.target.value.trim())}
                />
                <Button
                    type="submit"
                    className="header-search__search-button"
                    size="sm"
                    variant={searchContext?.searchText ? 'outline-primary' : 'dark'}
                    disabled={!inputText}
                >
                    <SearchIcon name="search-icon-image" />
                </Button>
                <div className="header-search__validation-message invalid-feedback pl-2 m-0">
                    {searchContext?.searchFormatError}
                </div>
            </div>
            <div className="form-row">
                <div className="col mt-2">{clearSearchButton}</div>
            </div>
        </form>
    );
};

export default HeaderSearchFields;
