export enum AutoRenewal {
    Yes = 'Yes',
    No = 'No',
    Blank = '',
}

export enum BanStatus {
    NotBanned = 'Not Banned',
    Banned = 'Banned',
    Blank = '',
}

export enum DeviceType {
    Switch = 'Nintendo Switch',
    SwitchLite = 'Nintendo Switch Lite',
    // This is an en dash, not a hyphen.
    SwitchOLED = 'Nintendo Switch – OLED Model',
}
