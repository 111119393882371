import './OrderIcon.scss';

import React, { FunctionComponent } from 'react';
import { ReactComponent as AgentIcon } from '../../../assets/agent.svg';
import { ReactComponent as ConsumerIcon } from '../../../assets/consumer.svg';
import { ReactComponent as ShoppingBagIcon } from '../../../assets/eshop-shopping-bag.svg';
import { OrderType } from './types';

export interface OrderIconProps {
    orderNumber: number | string;
    orderType: OrderType;
}

const resolveOrderIcon = (orderNumber: number | string, orderType: OrderType) => {
    if (orderType === OrderType.DIGITAL) {
        return <ShoppingBagIcon className="order-icon__icon" />;
    }
    if (orderType === OrderType.PHYSICAL) {
        if (orderNumber.toString().length === 10) {
            return <AgentIcon className="order-icon__icon" />;
        }
        return <ConsumerIcon className="order-icon__icon" />;
    }
    return null;
};

const OrderIcon: FunctionComponent<OrderIconProps> = ({ orderNumber, orderType }) => (
    <div className="order-icon">{resolveOrderIcon(orderNumber, orderType)}</div>
);

export default OrderIcon;
