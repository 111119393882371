import { HyruleToken } from '@nintendo/hyrule-react-commons';
import { useEffect, useState } from 'react';
import Utils from '../../../shared/Utils';
import { DeviceRepairApiModelResponse } from '../../../api/models';
import { DeviceApi } from '../../../api';
import { RepairModel } from '../../../types/RepairModel';

interface RepairsBySerialNumberHookData {
    data: RepairModel[];
    loading: boolean;
    notFound: boolean;
    error: boolean;
}

export const useRepairsBySerialNumber = (
    serialNumber: string,
    token: HyruleToken,
    correlationId: string,
): RepairsBySerialNumberHookData => {
    const [data, setData] = useState<RepairModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [notFound, setNotFound] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const getRepairs = async (): Promise<void> => {
        setLoading(true);

        const repairsResponse: DeviceRepairApiModelResponse = await DeviceApi.getDeviceRepairs(
            serialNumber,
            token,
            correlationId,
        );
        const { notFound, serverError, model } = repairsResponse;

        setNotFound(notFound);
        setError(serverError);
        setData(DeviceApi.mapToRepairModels(model.repairs));
        setLoading(false);
    };

    useEffect(() => {
        if (Utils.isValidSerialNumber(serialNumber) && token.accessToken) {
            getRepairs();
        } else {
            setData([]);
        }
    }, [serialNumber, token]);

    return { data, loading, notFound, error };
};
