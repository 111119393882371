import { HyruleToken } from '@nintendo/hyrule-react-commons';
import { ApiConfig } from '../environment/constants/api';
import DiceEnvironmentConfig from '../environment/config';

export abstract class BaseApi {
    static UnmappedField = 'UNMAPPED FIELD';

    static ApiConfig: () => ApiConfig = () => DiceEnvironmentConfig.getApiConfig();

    static MulesoftRequestInit = async <T = void>(
        method: 'GET' | 'POST',
        hyruleToken: HyruleToken,
        correlationId: string,
        requestBody?: T,
    ): Promise<RequestInit> => {
        const init: RequestInit = {
            method,
            headers: {
                Authorization: `Bearer ${hyruleToken.accessToken || ''}`,
                'x-correlation-id': correlationId,
            },
        };

        if (requestBody) {
            init.body = JSON.stringify(requestBody);
        }

        return init;
    };

    static async request<T>(url: RequestInfo, init?: RequestInit): Promise<T> {
        try {
            const response: Response = await fetch(url, init);
            return await response.json();
        } catch (error) {
            console.log(`BaseApi Error: ${error}`);
            throw error;
        }
    }
}
