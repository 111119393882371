import './BackToTopButton.scss';
import React, { FunctionComponent, useEffect, useState } from 'react';
import { HasScopes } from '@nintendo/hyrule-react-commons';
import { ReactComponent as BackToTopButtonIcon } from '../../../assets/back-to-top.svg';
import { validDiceScopes } from '../../../shared/ScopeUtils';

const BackToTopButton: FunctionComponent = () => {
    const [isButtonVisible, setIsButtonVisible] = useState(false);

    // How far down the page until the "Back to Top" button appears on the screen.
    // The intent here is <= 100 means it's hidden and > 100 means it's showing.
    const verticalPixelTolerance = 100;

    const reconcileButtonVisibility = (): void => {
        const { pageYOffset } = window;

        if (!isButtonVisible && pageYOffset > verticalPixelTolerance) {
            setIsButtonVisible(true);
        } else if (isButtonVisible && pageYOffset <= verticalPixelTolerance) {
            setIsButtonVisible(false);
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', reconcileButtonVisibility);

        // Remove previous event listener or it'll just keep adding the same
        // scroll event to window over and over again. This will result in
        // a bad memory leak. You can confirm this in Chrome dev tools by
        // looking at the getEventListeners(window).scroll array and ensuring
        // that there is only 1 entry.
        return (): void => window.removeEventListener('scroll', reconcileButtonVisibility);
    });

    const goBackToTop = (): void => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const buttonDisplay: 'flex' | 'none' = isButtonVisible ? 'flex' : 'none';
    const buttonStyle: React.CSSProperties | undefined = { display: buttonDisplay };

    return (
        <HasScopes scopes={validDiceScopes}>
            <BackToTopButtonIcon
                className="back-to-top-button"
                onClick={goBackToTop}
                style={buttonStyle}
            />
        </HasScopes>
    );
};

export default BackToTopButton;
