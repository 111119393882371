import React, { FunctionComponent } from 'react';
import StatusScreen from './StatusScreen';
import Footer from '../../../Footer';
import YoshiSleepingImagePng from '../../../../assets/yoshi-sleeping.png';
import YoshiSleepingImageWebp from '../../../../assets/yoshi-sleeping.webp';

const BlankStateScreen: FunctionComponent = () => (
    <>
        <StatusScreen
            statusImage={
                <picture>
                    <source srcSet={YoshiSleepingImageWebp} type="image/webp" />
                    <img src={YoshiSleepingImagePng} alt="" />
                </picture>
            }
            statusMessage="Use an email address, Nintendo Account ID (NAID), or Nintendo Switch serial number in the search bar above to display any information available."
            dataCy="nas-blank-state-component"
        />
        <Footer />
    </>
);

export default BlankStateScreen;
