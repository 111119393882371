import { Col, Row } from 'react-bootstrap';
import React, { FunctionComponent, ReactElement, useContext, useEffect } from 'react';
// eslint-disable-next-line import/no-unresolved
import { hasScope } from '@nintendo/hyrule-react-commons/lib/js/util/AuthUtils';
import { HyruleToken } from '@nintendo/hyrule-react-commons';
import { useHistory, useParams } from 'react-router-dom';
import AccountDetails from './account-details/AccountDetails';
import AccountDeviceDetails from './account-device-details/AccountDeviceDetails';
import BlankStateScreen from '../shared/components/nas-screens/BlankStateScreen';
import Footer from '../Footer';
import GamePlayData from './game-play/GamePlayData';
import GameRecommendations from './game-recommendations/GameRecommendations';
import HyruleTokenContext from '../contexts/HyruleTokenContext';
import LoadingScreen from '../shared/components/nas-screens/LoadingScreen';
import NoAccess from '../shared/components/auth/NoAccess';
import NotFoundScreen from '../shared/components/nas-screens/NotFoundScreen';
import RepairsPane from '../shared/components/repairs/RepairsPane';
import ServerErrorScreen from '../shared/components/nas-screens/ServerErrorScreen';
import { validDiceScopes } from '../../shared/ScopeUtils';
import { SearchContext } from '../contexts/SearchContext';
import { useAccount } from './hooks/useAccount';
import { useRepairsByEmail } from './hooks/useRepairsByEmail';
import { useOrders } from './hooks/useOrders';
import Orders from './orders/Orders';
import Utils from '../../shared/Utils';

const Account: FunctionComponent = (): ReactElement => {
    const searchContext = useContext(SearchContext);
    const token: HyruleToken = useContext(HyruleTokenContext);
    const history = useHistory();

    const email = searchContext?.resolvedEmail || '';
    const naid = searchContext?.resolvedNaid || '';
    const correlationId = searchContext?.correlationId || '';
    const { emailOrNaidParam } = useParams<{ emailOrNaidParam: string }>();

    const isParamFromADirectLinkThatShouldPerformASearch: boolean =
        !email &&
        !naid &&
        !!emailOrNaidParam &&
        emailOrNaidParam !== searchContext?.searchText &&
        Utils.isValidEmailOrNaid(emailOrNaidParam);

    useEffect(() => {
        if (isParamFromADirectLinkThatShouldPerformASearch && token.accessToken) {
            searchContext?.performSearch(emailOrNaidParam, history);
        }
    }, [emailOrNaidParam, searchContext?.searchText, token.accessToken]);

    const { loading: isAccountLoading, data: accountByNaid } = useAccount(
        naid,
        email,
        token,
        correlationId,
    );

    const {
        orders,
        isLoading: isOrdersLoading,
        isError: ordersHasError,
        isNotFound: ordersNotFound,
    } = useOrders(naid, email, token, correlationId);

    const {
        data: repairs,
        notFound: repairsNotFound,
        loading: isRepairsLoading,
        error: repairsHasError,
    } = useRepairsByEmail(email, token, correlationId);

    if (!hasScope(validDiceScopes)) {
        return <NoAccess />;
    }

    if (searchContext?.isNintendoAccountError && !naid && ordersHasError && repairsHasError) {
        return <ServerErrorScreen />;
    }

    if (searchContext?.isNintendoAccountLoading) {
        return <LoadingScreen />;
    }

    if (searchContext?.isNintendoAccountNotFound && !naid && ordersNotFound && repairsNotFound) {
        return <NotFoundScreen />;
    }

    if (!naid && !email) {
        return <BlankStateScreen />;
    }

    return (
        <>
            <AccountDetails
                isLoading={isAccountLoading}
                account={accountByNaid.account}
                lastUpdated={accountByNaid.lastUpdated}
            />
            <AccountDeviceDetails
                isLoading={isAccountLoading}
                devices={accountByNaid.devices}
                lastUpdated={accountByNaid.lastUpdated}
            />
            <Row>
                <Col>
                    <RepairsPane
                        isLoading={isRepairsLoading}
                        repairs={repairs}
                        displayNotFound={repairsNotFound}
                        displayServerError={repairsHasError}
                        headerText="Repairs"
                    />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Orders
                        orders={orders}
                        isError={ordersHasError}
                        isLoading={isOrdersLoading}
                        isNotFound={ordersNotFound}
                    />
                </Col>
            </Row>
            <Row>
                <Col lg={8}>
                    <GamePlayData />
                </Col>
                <Col lg={4}>
                    <GameRecommendations />
                </Col>
            </Row>
            <Footer />
        </>
    );
};

export default Account;
