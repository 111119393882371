import React, { FunctionComponent } from 'react';
import OutlineMessage from '../outline-messages/OutlineMessage';
import { ReactComponent as ShellOutlineImage } from '../../../../assets/shell-outline.svg';

const RepairsNotFoundMessage: FunctionComponent = () => (
    <OutlineMessage
        message="There currently isn't any Device Repair information associated with this search."
        outlineImage={<ShellOutlineImage name="shell-outline-image" />}
        messageLocation="message-right"
    />
);

export default RepairsNotFoundMessage;
