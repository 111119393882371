import { OverlayTrigger, Popover } from 'react-bootstrap';
import React, { FunctionComponent } from 'react';
import { ReactComponent as CopyIcon } from '../assets/copy.svg';
import Utils from './Utils';
import './CopyButton.scss';

interface CopyButtonProps {
    textToCopy: string;
    title: string;
    id: string;
}

export const CopyButton: FunctionComponent<CopyButtonProps> = ({ textToCopy, title, id }) => {
    const popover = (
        <Popover id={id}>
            <Popover.Content>{textToCopy} copied to clipboard!</Popover.Content>
        </Popover>
    );

    return (
        <OverlayTrigger trigger="click" placement="right" overlay={popover} rootClose>
            <button
                className="button-reset"
                type="button"
                title={title}
                onClick={(): void => Utils.copyToClipboard(textToCopy)}
            >
                <CopyIcon className="copy-button__icon" />
            </button>
        </OverlayTrigger>
    );
};
