import React, { FunctionComponent } from 'react';
import OutlineMessage from '../../shared/components/outline-messages/OutlineMessage';
import { ReactComponent as ShellOutlineImage } from '../../../assets/shell-outline.svg';

const GameRecommendationsNoResultsFoundMessage: FunctionComponent = () => (
    <OutlineMessage
        message="There currently aren't any Game Recommendations available."
        messageLocation="message-bottom"
        outlineImage={<ShellOutlineImage name="shell-outline-image" />}
    />
);

export default GameRecommendationsNoResultsFoundMessage;
