import dayjs from 'dayjs';

export enum OrderType {
    DIGITAL = 'digital',
    PHYSICAL = 'physical',
}

export enum OrderSubType {
    ServiceOrderStoreMagento = 'sosmagento',
    MyNintendoStorePhysical = 'mnsphysical',
    ESHOP = 'eshop',
}

export interface OrderModel {
    type: OrderType;
    id: number | string;
    country: string;
    date: dayjs.Dayjs;
    items: OrderItem[];
    contentType?: number;
    channel?: string;
    subType: OrderSubType;
}

export interface OrderItem {
    description: string;
    orderStatus?: string;
    shipDate?: dayjs.Dayjs;
}
