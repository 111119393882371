import './GameCard.scss';

import React, { FunctionComponent } from 'react';
import GamePlayHistoryDetailsPopover from './GamePlayHistoryDetailsPopover';
import NoCoverImage from '../../../assets/no-cover-vertical.svg';
import { resizeImageUrl } from '../../../shared/CdnImages';
import { GamePlayDataModel } from './GamePlayDataModel';

const GameCard: FunctionComponent<GamePlayDataModel> = ({
    consumerId,
    applicationId,
    imageUrl,
    title,
    playTime,
}) => (
    <div className="game-card">
        <div className="game-card__image">
            <img src={imageUrl ? resizeImageUrl(imageUrl, 190) : NoCoverImage} alt={title} />
        </div>
        <div className="game-card__text">
            <div className="game-card__title">{title}</div>
            <div className="game-card__play-time">
                <div>
                    <GamePlayHistoryDetailsPopover
                        applicationId={applicationId}
                        consumerId={consumerId}
                        buttonLabel="Total Play Time:"
                        daysAgo={14}
                    />
                </div>
                <div className={playTime ? '' : 'font-italic'}>{playTime || 'Unavailable'}</div>
            </div>
        </div>
    </div>
);

export default GameCard;
