import { ApiConfig, devApiConfig, prodApiConfig, testApiConfig } from './constants/api';
import { devFeatureFlags, FeatureFlags, prodFeatureFlags, testFeatureFlags } from './feature-flags';
import { DiceEnvironment, HyruleAuthServerConfig } from './types';

class DiceEnvironmentConfig {
    /**
     * @returns The DICE environment being run (i.e. dev, test, or prod).
     */
    static getDiceEnv = (): DiceEnvironment => {
        const diceEnv: DiceEnvironment = process.env.REACT_APP_DICE_ENV as DiceEnvironment;

        if (diceEnv === 'dev' || diceEnv === 'test' || diceEnv === 'prod') {
            return diceEnv;
        }
        throw new Error(
            'ERROR getDiceEnv(): The DICE environment must be either dev, test, or prod!',
        );
    };

    /**
     * @returns The DICE environment specific Hyrule Auth Server configuration values.
     */
    static getHyruleAuthServerConfig(): HyruleAuthServerConfig {
        const diceEnv: DiceEnvironment = DiceEnvironmentConfig.getDiceEnv();

        switch (diceEnv) {
            case 'prod':
                return {
                    authServerBaseUri: 'https://hyrule-auth.noa.com',
                    clientId: 'G7Xbxnmft1wKRyfrS74NDJy39Zu2MGjv',
                };
            case 'test':
                return {
                    authServerBaseUri: 'https://hyrule-auth-test.noa.com',
                    clientId: 'dEpTDNQQdAtxiK81q93rDvtrk6Xwmsj3',
                };
            default:
                return {
                    authServerBaseUri: 'https://hyrule-auth-dev.noa.com',
                    clientId: 'UMKFf4Z6tMs9PtdUdf8DQKcCdAQC2e3P',
                };
        }
    }

    /**
     * Get the DICE environment specific feature flag configuration.
     * @returns The DICE environment specific feature flag configuration values.
     */
    static getFeatureFlagConfig = (): FeatureFlags => {
        // Determine which environment we're running under.
        const diceEnv: DiceEnvironment = DiceEnvironmentConfig.getDiceEnv();

        if (diceEnv === 'dev') {
            return devFeatureFlags;
        }

        if (diceEnv === 'test') {
            return testFeatureFlags;
        }

        if (diceEnv === 'prod') {
            return prodFeatureFlags;
        }
        throw new Error(
            'ERROR getFeatureFlagConfig(): The DICE environment must be either dev, test, or prod!',
        );
    };

    static setupHyruleAuthServerConfigOnWindow(): void {
        const config: HyruleAuthServerConfig = DiceEnvironmentConfig.getHyruleAuthServerConfig();

        window.config.authServerBaseUri = config.authServerBaseUri;
        window.config.clientId = config.clientId;
    }

    static getApiConfig = (): ApiConfig => {
        const diceEnv: DiceEnvironment = DiceEnvironmentConfig.getDiceEnv();

        if (diceEnv === 'dev') {
            return devApiConfig;
        }

        if (diceEnv === 'test') {
            return testApiConfig;
        }

        if (diceEnv === 'prod') {
            return prodApiConfig;
        }
        throw new Error(
            'ERROR getApiConfig(): The DICE environment must be either dev, test, or prod!',
        );
    };
}

export default DiceEnvironmentConfig;
