import React, { FunctionComponent } from 'react';
import './SearchField.scss';

interface SearchFieldProps {
    placeholder: string;
    onQueryChange: (searchQuery: string) => void;
}

export const SearchField: FunctionComponent<SearchFieldProps> = ({
    placeholder,
    onQueryChange,
}) => (
    <div className="search-field">
        <input
            className="search-field__input pl-4"
            type="search"
            placeholder={placeholder}
            aria-label={placeholder}
            onChange={(e) => onQueryChange(e.target.value)}
        />
    </div>
);
