import React, { FunctionComponent } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import Utils from '../../../shared/Utils';

export interface CopyToClipboardWithLogoButtonProps {
    id: string;
    imgSrc: string;
    imgAlt: string;
    textToCopy: string;
}

const CopyToClipboardWithLogoButton: FunctionComponent<CopyToClipboardWithLogoButtonProps> = ({
    id,
    imgSrc,
    imgAlt,
    textToCopy,
}) => {
    const copyTextToClipboard = (): void => {
        Utils.copyToClipboard(textToCopy);
    };

    const popover = (
        <Popover id={id}>
            <Popover.Content>Link copied to clipboard!</Popover.Content>
        </Popover>
    );

    return (
        <div>
            <OverlayTrigger trigger="click" placement="right" overlay={popover} rootClose>
                <button className="button-reset" type="button" onClick={copyTextToClipboard}>
                    <img src={imgSrc} alt={imgAlt} width="25" height="25" />
                </button>
            </OverlayTrigger>
        </div>
    );
};

export default CopyToClipboardWithLogoButton;
