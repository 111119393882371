import './GameRecommendations.scss';

import React, { ReactElement, useContext } from 'react';
import { HyruleToken } from '@nintendo/hyrule-react-commons';
import CollapsiblePane from '../../shared/components/CollapsiblePane';
import GameRecommendationsNoResultsFoundMessage from './GameRecommendationsNoResultsFoundMessage';
import HyruleTokenContext from '../../contexts/HyruleTokenContext';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import ServerErrorMessage from '../../shared/components/outline-messages/ServerErrorMessage';
import { SearchContext } from '../../contexts/SearchContext';
import { GameRecommendationEntryModel } from './GameRecommendationEntryModel';
import { useGameRecommendations } from './useGameRecommendations';
import { resizeImageUrl } from '../../../shared/CdnImages';
import NoCoverImage from '../../../assets/no-cover-vertical.svg';

const GameRecommendations = (): ReactElement => {
    const searchContext = useContext(SearchContext);
    const resolvedNaid = searchContext?.resolvedNaid || '';
    const correlationId = searchContext?.correlationId || '';
    const token: HyruleToken = useContext(HyruleTokenContext);
    const { data, loading, error } = useGameRecommendations(resolvedNaid, token, correlationId);

    const loadingSpinner: ReactElement = (
        <div className="p-3 text-center">
            <LoadingSpinner />
        </div>
    );

    let paneContent: ReactElement = (
        <div className="game-recommendations row no-gutters">
            {data.map((model: GameRecommendationEntryModel) => (
                <div
                    key={model.nsuid}
                    className="game-recommendation-entry row no-gutters col-4 col-sm-12"
                >
                    <div className="image col-auto">
                        <img
                            src={
                                model.imageUrl ? resizeImageUrl(model.imageUrl, 190) : NoCoverImage
                            }
                            alt={model.title}
                        />
                    </div>
                    <div className="title col">{model.title}</div>
                </div>
            ))}
        </div>
    );

    if (!data.length) {
        paneContent = <GameRecommendationsNoResultsFoundMessage />;
    }

    if (error) {
        paneContent = <ServerErrorMessage />;
    }

    return (
        <CollapsiblePane
            eventKey="GameRecommendations"
            headerText="Game Recommendations"
            disablePaneContentPadding
            dataCyForBody="game-recommendations"
        >
            {loading && loadingSpinner}
            {!loading && paneContent}
        </CollapsiblePane>
    );
};

export default GameRecommendations;
