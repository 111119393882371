import { HyruleToken } from '@nintendo/hyrule-react-commons';
import { PartOrderApiModel, PartOrderApiModelRequest } from './models';
import { BaseApi } from './BaseApi';
import { HttpStatusCode } from '../shared/HttpStatusCode';

export enum ProductType {
    Physical = 'Physical',
    Digital = 'Digital',
}

export enum OrderLineStatus {
    BOOKED = 'BOOKED',
    CANCELLED = 'CANCELLED',
    AWAITING_SHIPPING = 'AWAITING_SHIPPING',
    SHIPPED = 'SHIPPED',
    PICKED = 'PICKED',
    PICKED_PARTIAL = 'PICKED_PARTIAL',
    ENTERED = 'ENTERED',
    FULFILLED = 'FULFILLED',
    INVOICED = 'INVOICED',
    CLOSED = 'CLOSED',
    AWAITING_FULFILLMENT = 'AWAITING_FULFILLMENT',
}

export const DigitalOrderStatusMap: Map<OrderLineStatus, string> = new Map([
    [OrderLineStatus.BOOKED, 'Processing'],
    [OrderLineStatus.ENTERED, 'Processing'],
    [OrderLineStatus.FULFILLED, 'Code Delivered'],
    [OrderLineStatus.INVOICED, 'Code Delivered'],
    [OrderLineStatus.CLOSED, 'Code Delivered'],
    [OrderLineStatus.AWAITING_FULFILLMENT, 'Code Delivered'],
]);

export const PhysicalOrderStatusMap = new Map([
    [OrderLineStatus.BOOKED, 'Processing'],
    [OrderLineStatus.CANCELLED, 'Cancelled'],
    [OrderLineStatus.ENTERED, 'Processing'],
    [OrderLineStatus.INVOICED, 'Shipped'],
    [OrderLineStatus.CLOSED, 'Shipped'],
    [OrderLineStatus.AWAITING_SHIPPING, 'Processing'],
    [OrderLineStatus.SHIPPED, 'Shipped'],
    [OrderLineStatus.PICKED, 'Picked'],
    [OrderLineStatus.PICKED_PARTIAL, 'Picked'],
]);

export const resolveApiUrl = (request: PartOrderApiModelRequest): string => {
    const { accountApiUrls } = BaseApi.ApiConfig();
    const { naid, email } = request;

    if (!!naid && !email) {
        return accountApiUrls.partOrdersByNaid(naid);
    }
    if (!naid && !!email) {
        return accountApiUrls.partOrdersByEmail(encodeURIComponent(email));
    }
    if (!!naid && !!email) {
        return accountApiUrls.partOrdersByEmailAndNaid(encodeURIComponent(email), naid);
    }
    throw new Error('Could not resolve part orders API URL!');
};

export class PartOrderApi {
    static async getPartOrders(
        request: PartOrderApiModelRequest,
        token: HyruleToken,
        correlationId: string,
    ): Promise<PartOrderApiModel> {
        const url: string = resolveApiUrl(request);
        const init: RequestInit = await BaseApi.MulesoftRequestInit('GET', token, correlationId);

        try {
            const response: Response = await fetch(url, init);

            if (!response.ok && response?.status !== HttpStatusCode.NotFound) {
                throw new Error('Error getting part orders!');
            }
            return response.ok ? await response.json() : null;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}
