import { HyruleToken } from '@nintendo/hyrule-react-commons';
import { useEShopOrders } from './useEShopOrders';
import { usePartOrders } from './usePartOrders';
import { OrderModel } from '../orders/types';
import { OrdersHookData } from './types';

const orderDateDesc = (a: OrderModel, b: OrderModel) => b.date.unix() - a.date.unix();

export const useOrders = (
    naid: string,
    email: string,
    token: HyruleToken,
    correlationId: string,
): OrdersHookData => {
    const {
        orders: eshopOrders,
        isLoading: eshopIsLoading,
        isNotFound: eshopIsNotFound,
        isError: eshopIsError,
    } = useEShopOrders(naid, token, correlationId);
    const {
        orders: partOrders,
        isLoading: partsIsLoading,
        isNotFound: partsIsNotFound,
        isError: partsIsError,
    } = usePartOrders(naid, email, token, correlationId);

    return {
        orders: [...partOrders, ...eshopOrders].sort(orderDateDesc),
        isLoading: partsIsLoading || eshopIsLoading,
        isNotFound: partsIsNotFound && eshopIsNotFound,
        isError: partsIsError || eshopIsError,
    };
};
