import dayjs, { Dayjs } from 'dayjs';
import React, { FunctionComponent } from 'react';
import duration from 'dayjs/plugin/duration.js';
import Utils from '../../../../shared/Utils';
import './LastUpdated.scss';

dayjs.extend(duration);

interface LastUpdatedProps {
    dateTime: Dayjs;
}

export const LastUpdated: FunctionComponent<LastUpdatedProps> = ({ dateTime }) => {
    if (dateTime.isValid()) {
        const secondsBetweenUpdatedAndNow: number = dayjs
            .duration(dayjs().diff(dateTime))
            .asSeconds();
        const { hours, minutes } = Utils.secondsToHoursAndMinutes(secondsBetweenUpdatedAndNow);
        const h: string = hours ? `${hours} hour(s) ` : '';
        const m: string = minutes ? `${minutes} minute(s)` : '';
        const hm: string = `${h}${m}`.trim();
        const lastUpdatedText = `Last updated ${hm} ago`;

        return (
            <time
                data-testid="last-updated"
                className="last-updated"
                dateTime={dateTime.toISOString()}
            >
                {lastUpdatedText}
            </time>
        );
    }
    return null;
};
