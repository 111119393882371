import { HyruleToken } from '@nintendo/hyrule-react-commons';
import { useQuery } from 'react-query';
import dayjs from 'dayjs';
import { useEffect } from 'react';
import {
    DigitalOrderStatusMap,
    PhysicalOrderStatusMap,
    OrderLineStatus,
    PartOrderApi,
    ProductType,
} from '../../../api';
import { OrderSubType, OrderType } from '../orders/types';
import { PartOrderApiModel, PartOrderEntryApiModel } from '../../../api/models';
import { OrdersHookData } from './types';

export const usePartOrders = (
    naid: string,
    email: string,
    token: HyruleToken,
    correlationId: string,
): OrdersHookData => {
    const { data, isLoading, isRefetching, isError, refetch } = useQuery<PartOrderApiModel | null>(
        ['part-orders', { naid, email }],
        async () => {
            if ((naid || email) && correlationId && token)
                return PartOrderApi.getPartOrders({ email, naid }, token, correlationId);

            return Promise.resolve(null);
        },
        {
            cacheTime: 1000 * 60 * 10, // TODO what is our TTL?
        },
    );

    const getOrderSubType = (partOrder: PartOrderEntryApiModel) => {
        const partOrderNumber = partOrder.order_number.toString();
        if (partOrderNumber.length === 10) {
            return OrderSubType.ServiceOrderStoreMagento;
        }
        if (partOrderNumber.length === 11) {
            return OrderSubType.MyNintendoStorePhysical;
        }

        return OrderSubType.ESHOP;
    };

    const orders =
        data?.part_orders?.map((partOrder) => ({
            type: OrderType.PHYSICAL,
            subType: getOrderSubType(partOrder),
            id: partOrder.order_number,
            date: dayjs(partOrder.date_setup),
            country: partOrder.country,
            items:
                partOrder.items?.map((item) => ({
                    description: item.description,
                    orderStatus: parseStatus(
                        item.order_status as OrderLineStatus,
                        item.product_type,
                    ),
                    shipDate: dayjs(item.ship_date),
                })) || [],
        })) || [];

    // TODO DICE-1542 debug why this is needed and refactor
    useEffect(() => {
        refetch();
    }, [naid, email]);

    return {
        orders,
        isLoading: isLoading || isRefetching,
        isNotFound: !isError && orders.length === 0,
        isError,
        refetch,
    };
};

const parseStatus = (status: OrderLineStatus, productType: string): string => {
    let map: Map<OrderLineStatus, string> = new Map();
    if (productType === ProductType.Digital) {
        map = DigitalOrderStatusMap;
    } else if (productType === ProductType.Physical) {
        map = PhysicalOrderStatusMap;
    } else {
        return status;
    }

    return map.get(status) || 'Unavailable';
};
