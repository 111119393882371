import './RadioPill.scss';

import React, { FunctionComponent } from 'react';

export interface PillButtonProps {
    id: string;
    name: string;
    className?: string;
    value: string;
    text: string;
    active: boolean;
    disabled?: boolean;
    onChange: (value: string) => void;
}

export const RadioPill: FunctionComponent<PillButtonProps> = ({
    id,
    name,
    className,
    value,
    text,
    active,
    disabled,
    onChange,
}) => (
    <div className={`radio-pill ${className ? `${className}` : ''}`}>
        <input
            id={id}
            type="radio"
            name={name}
            className="radio-pill__radio"
            value={value}
            checked={active}
            disabled={disabled}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                onChange(e.currentTarget.value);
            }}
        />
        <label htmlFor={id} className="radio-pill__pill">
            {text}
        </label>
    </div>
);
