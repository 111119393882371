import React, { FunctionComponent } from 'react';
import OutlineMessage from '../../shared/components/outline-messages/OutlineMessage';
import { ReactComponent as ShellOutlineImage } from '../../../assets/shell-outline.svg';

interface GamesPlayedMessageProps {
    numGamesPlayed: number;
}

const LessThanFiveGamesPlayedMessage: FunctionComponent<GamesPlayedMessageProps> = ({
    numGamesPlayed,
}) => {
    const message: string =
        numGamesPlayed === 0 ? 'No games played yet.' : 'No additional games played.';

    return (
        <OutlineMessage
            message={message}
            messageLocation="message-bottom"
            outlineImage={<ShellOutlineImage name="shell-outline-image" />}
            dataCy="game-play-data-placeholder"
        />
    );
};

export default LessThanFiveGamesPlayedMessage;
