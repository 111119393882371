import { HyruleToken } from '@nintendo/hyrule-react-commons';
import { NintendoAccountRequest, NintendoAccountResponse } from './models';
import { BaseApi } from '.';
import { HttpStatusCode } from '../shared/HttpStatusCode';

const searchByEmail = (request: NintendoAccountRequest): boolean =>
    !!request.email && !request.naid;
const searchByNaid = (request: NintendoAccountRequest): boolean => !!request.naid && !request.email;

export class NintendoAccountApi {
    static async getNaidOrEmail(
        request: NintendoAccountRequest,
        hyruleToken: HyruleToken,
        correlationId: string,
    ): Promise<NintendoAccountResponse> {
        const options: RequestInit = {
            method: 'POST',
            headers: {
                Authorization: `Bearer ${hyruleToken.accessToken || ''}`,
                'x-correlation-id': correlationId,
            },
            body: JSON.stringify(request),
        };

        const { nasUrl } = BaseApi.ApiConfig();

        // There are three different use cases we care about here:
        // 1. It passes and we get the desired NAID or email (or HTTP 200 OK).
        // 2. No results were found matching our search (or HTTP 404 Not Found).
        // 3. Any other unsuccessful HTTP responses outside of not finding anything (use case #2).
        try {
            const response: Response = await fetch(nasUrl, options);

            // Not found means we received an HTTP 404 Not Found response.
            const nasNotFound: boolean = response.status === HttpStatusCode.NotFound;

            // An error occurred means it threw an error (which we'll catch below)
            // or it's successfully returning an HTTP 500 Internal Server Error.
            // We want to ignore the HTTP 404 Not Found use case because while it's
            // technically an error, we handle it a specific way. The only time we
            // will show a full screen Colored Shell (Not Found) or Colored Boo (Server Error)
            // is if we're passing in an email. If we're passing in an NAID, then we
            // already have the necessary data to search other sections.
            const nasError: boolean = !response.ok && response.status !== HttpStatusCode.NotFound;

            // If we get an unsuccessful response, then we'll simply return "Unavailable" and
            // log the NAS service error message. Otherwise, it was successful and we should
            // return what the NAS service returned.
            let emailOrNaid: string | undefined = await response.text();
            if (!response.ok) {
                console.error(`NasApi Error: ${emailOrNaid}`);
                emailOrNaid = undefined;
            }

            return {
                nasNotFound,
                nasError,
                email: searchByEmail(request) ? request.email : emailOrNaid,
                naid: searchByNaid(request) ? request.naid : emailOrNaid,
            } as NintendoAccountResponse;
        } catch (error) {
            console.error(`NasApi Error: ${error}`);

            return {
                nasNotFound: false,
                nasError: true,
                email: undefined,
                naid: undefined,
            } as NintendoAccountResponse;
        }
    }
}
