import dayjs from 'dayjs';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter.js';
import duration from 'dayjs/plugin/duration.js';
import { HyruleToken } from '@nintendo/hyrule-react-commons';
import { GamePlayHistoryDetailApiModel, PlayedDay } from './models';
import { BaseApi } from './BaseApi';

import Utils from '../shared/Utils';
import { GamePlayedDuration } from '../components/account/game-play/GamePlayedDuration';

export class GamePlayHistoryDetailApi {
    static async getGamePlayHistoryDetail(
        naid: string,
        applicationId: string,
        token: HyruleToken,
        correlationId: string,
    ): Promise<GamePlayHistoryDetailApiModel> {
        const { accountApiUrls } = BaseApi.ApiConfig();
        const url: string = accountApiUrls.gameSummaryDetails(naid, applicationId);
        const init: RequestInit = await BaseApi.MulesoftRequestInit('GET', token, correlationId);

        try {
            const response: Response = await fetch(url, init);
            return await response.json();
        } catch (error) {
            console.log(`Error: ${error}`);
            throw error;
        }
    }

    private static consolidatePlayedDays(playedDays: PlayedDay[]): PlayedDay[] {
        const consolidatedDict: { [playedDate: string]: PlayedDay } = {};
        const consolidatedList: PlayedDay[] = [];

        for (let i = 0; i < playedDays.length; i++) {
            const playedDay = playedDays[i];
            // If an entry for this date (i.e. '2017-05-22') exists,
            // then go ahead and add the minutes played to the existing
            // entry since we're consolidating data.
            if (consolidatedDict[playedDay.playeddate]) {
                consolidatedDict[playedDay.playeddate].minutes_played += playedDay.minutes_played;
                // Otherwise, add a new entry for this date.
            } else {
                consolidatedDict[playedDay.playeddate] = {
                    minutes_played: playedDay.minutes_played,
                    playeddate: playedDay.playeddate,
                };
            }
        }

        Object.keys(consolidatedDict).forEach((key) =>
            consolidatedList.push(consolidatedDict[key]),
        );

        return consolidatedList;
    }

    static mapToGamePlayedDuration(playedDays: PlayedDay[], daysAgo: number): GamePlayedDuration[] {
        if (!playedDays) {
            return [];
        }

        const today: dayjs.Dayjs = dayjs().startOf('day');

        const filteredPlayedDaysToTheLastFourteenDays: PlayedDay[] = playedDays.filter(
            (playedDay) => {
                const fourteenDaysAgo: dayjs.Dayjs = today.clone().subtract(daysAgo, 'days');
                dayjs.extend(isSameOrAfter);
                return dayjs(playedDay.playeddate).isSameOrAfter(fourteenDaysAgo);
            },
        );

        const consolidatedPlayedDays: PlayedDay[] = GamePlayHistoryDetailApi.consolidatePlayedDays(
            filteredPlayedDaysToTheLastFourteenDays,
        );
        const sorted: PlayedDay[] = consolidatedPlayedDays.sort(
            (a, b) => dayjs(b.playeddate).unix() - dayjs(a.playeddate).unix(),
        );

        return sorted.map((x) => {
            const datePlayed: dayjs.Dayjs = dayjs(x.playeddate);
            dayjs.extend(duration);
            const minutesPlayed = dayjs.duration(x.minutes_played, 'minute');
            const { hours, minutes } = Utils.secondsToHoursAndMinutes(minutesPlayed.asSeconds());
            const dateTitle = `${datePlayed.format('MMM DD')}`;

            return {
                dateTitle,
                numHours: hours,
                numMinutes: minutes,
            } as GamePlayedDuration;
        });
    }
}
