const typeMap = {
    1: 'Game Title',
    2: 'Nintendo Switch Game Voucher',
    3: 'Trial',
    4: 'DLC',
    5: 'Bundle',
    6: 'Subscription',
    7: 'Consumable',
};

const isContentType = (number: number): number is keyof typeof typeMap => number in typeMap;

export const getOrderType = (contentType: number): string => {
    if (isContentType(contentType)) {
        return typeMap[contentType];
    }

    return '';
};
