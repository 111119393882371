import './OutlineMessage.scss';
import React, { FunctionComponent, ReactElement } from 'react';

export interface OutlineMessageProps {
    dataCy?: string;
    message: string;
    messageLocation: 'message-right' | 'message-bottom';
    outlineImage: ReactElement;
    additionalClasses?: string;
}

const OutlineMessage: FunctionComponent<OutlineMessageProps> = ({
    message,
    messageLocation,
    outlineImage,
    additionalClasses,
    dataCy,
}) => (
    <div data-cy={dataCy} className={`outline-message ${messageLocation} ${additionalClasses}`}>
        <div className="image">{outlineImage}</div>
        <div className={`message ${messageLocation}`}>{message}</div>
    </div>
);

export default OutlineMessage;
