import React, { FunctionComponent, ReactElement, useContext } from 'react';
import { HyruleToken } from '@nintendo/hyrule-react-commons';
import ErrorCodesTable from './ErrorCodesTable';
import CollapsiblePane from '../../shared/components/CollapsiblePane';
import HyruleTokenContext from '../../contexts/HyruleTokenContext';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import NoAssociatedDataErrorMessage from './ErrorCodes-NoErrorsMessage';
import ServerErrorMessage from '../../shared/components/outline-messages/ServerErrorMessage';
import { SearchContext } from '../../contexts/SearchContext';
import { useErrorCodes } from './useErrorCodes';

const ErrorCodes: FunctionComponent = () => {
    const searchContext = useContext(SearchContext);
    const serialNumber = searchContext?.resolvedSerialNumber || '';
    const correlationId = searchContext?.correlationId || '';
    const token: HyruleToken = useContext(HyruleTokenContext);

    const {
        data: errorCodes,
        error,
        loading,
        notFound,
    } = useErrorCodes(serialNumber, token, correlationId);

    const loadingSpinner: ReactElement = (
        <div className="text-center">
            <LoadingSpinner />
        </div>
    );

    let content: ReactElement = <ErrorCodesTable errorCodes={errorCodes} />;

    if (notFound) {
        content = <NoAssociatedDataErrorMessage />;
    }

    if (error) {
        content = <ServerErrorMessage />;
    }

    const paneContent: ReactElement = <div className="text-center">{content}</div>;

    return (
        <CollapsiblePane
            eventKey="ErrorCodes"
            headerText="Error Codes"
            disablePaneContentPadding={!loading}
            dataCyForBody="error-codes"
        >
            {loading && loadingSpinner}
            {!loading && paneContent}
        </CollapsiblePane>
    );
};

export default ErrorCodes;
