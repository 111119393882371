import React, { FunctionComponent } from 'react';

export interface AccountDetailPairProps {
    label: string;
    content: string;
    testId?: string;
}

const LabelClasses = 'col-5 col-sm-3 font-weight-bold';
const ContentClasses = 'col-7 col-sm-3';

export const AccountDetailPair: FunctionComponent<AccountDetailPairProps> = ({
    label,
    content,
    testId,
}) => {
    const pairContentClasses: string =
        ContentClasses + (content === 'Unavailable' || content === 'None' ? ' font-italic' : '');

    return (
        <>
            <div className={LabelClasses}>{label}:</div>
            <div className={pairContentClasses} data-testid={testId}>
                {content}
            </div>
        </>
    );
};
