import React, { FunctionComponent, ReactElement, useContext } from 'react';
import { HyruleToken } from '@nintendo/hyrule-react-commons';
import HyruleTokenContext from '../../contexts/HyruleTokenContext';
import RepairsPane from '../../shared/components/repairs/RepairsPane';
import { SearchContext } from '../../contexts/SearchContext';
import { useRepairsBySerialNumber } from './useRepairsBySerialNumber';

const Repairs: FunctionComponent = (): ReactElement => {
    const searchContext = useContext(SearchContext);
    const serialNumber = searchContext?.resolvedSerialNumber || '';
    const correlationId = searchContext?.correlationId || '';
    const token: HyruleToken = useContext(HyruleTokenContext);
    const {
        data: repairs,
        loading,
        error,
        notFound,
    } = useRepairsBySerialNumber(serialNumber, token, correlationId);

    return (
        <RepairsPane
            isLoading={loading}
            repairs={repairs}
            displayNotFound={notFound}
            displayServerError={error}
            headerText="Device Repairs"
        />
    );
};

export default Repairs;
