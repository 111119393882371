import './ErrorCodesTable.scss';
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { Collapse } from 'react-bootstrap';
import DiceTable, {
    DiceTableProps,
    DiceTableRowInterface,
} from '../../shared/components/DiceTable';
import { ReactComponent as ChevronClosed } from '../../../assets/chevron-closed.svg';
import { ReactComponent as ChevronOpen } from '../../../assets/chevron-open.svg';
import { DateFormat } from '../../../shared/DateFormat';
import { ErrorCodeModel } from './ErrorCodeModel';
import { CopyButton } from '../../../shared/CopyButton';

const ErrorCodeChevron: FunctionComponent<{
    open: boolean;
    rowNumber: number;
    setOpen: (open: boolean) => void;
}> = ({ open, rowNumber, setOpen }) => {
    if (open) {
        return (
            <ChevronOpen
                key={rowNumber}
                data-cy={`chevron-open-close-${rowNumber}`}
                className="chevron-open-close"
                onClick={(): void => setOpen(false)}
                aria-controls={`error-code-row-${rowNumber}`}
                aria-expanded="true"
            />
        );
    }

    return (
        <ChevronClosed
            key={rowNumber}
            data-cy={`chevron-open-close-${rowNumber}`}
            className="chevron-open-close"
            onClick={(): void => setOpen(true)}
            aria-controls={`error-code-row-${rowNumber}`}
            aria-expanded="false"
        />
    );
};

const errorCodeColumn = (
    model: ErrorCodeModel,
    open: boolean,
    setOpen: (open: boolean) => void,
    rowNumber: number,
): ReactElement => (
    <>
        <span className="d-inline-block mr-2">{model.errorCode}</span>
        <CopyButton
            textToCopy={model.errorCode}
            title={`Copy ${model.errorCode} to clipboard.`}
            id={`${model.errorCode}-${rowNumber}`}
        />
        {model.count > 1 && (
            <ErrorCodeChevron open={open} rowNumber={rowNumber} setOpen={setOpen} />
        )}
    </>
);

const countColumn = (model: ErrorCodeModel): ReactElement => <span>{model.count}</span>;

const datesOccurredColumn = (
    model: ErrorCodeModel,
    open: boolean,
    rowNumber: number,
): ReactElement => (
    <>
        {model.datesOccurred.slice(0, 11).map((x, index) => {
            const dateAndTime: string = x.format(DateFormat.dateWithTime);

            if (index === 0) {
                // eslint-disable-next-line react/no-array-index-key
                return <div key={`error-code-row-${rowNumber}-game-${index}`}>{dateAndTime}</div>;
            }
            return (
                // eslint-disable-next-line react/no-array-index-key
                <Collapse in={open} key={`error-code-row-${rowNumber}-date-${index}`}>
                    <div id={`error-code-row-${rowNumber}`}>
                        {/* We'll only ever render 10 Date/Time entries at maximum. If there are more than that,
              then we'll render an 11th line that says '...and other times' and that will be it. */}
                        {index < 10 ? dateAndTime : '...and other times'}
                    </div>
                </Collapse>
            );
        })}
    </>
);

const gameUsedColumn = (model: ErrorCodeModel, open: boolean, rowNumber: number): ReactElement => (
    <>
        {model.gamesUsed.slice(0, 11).map((x, index) => {
            if (index === 0) {
                // eslint-disable-next-line react/no-array-index-key
                return <div key={`error-code-row-${rowNumber}-game-${index}`}>{x}</div>;
            }
            return (
                // eslint-disable-next-line react/no-array-index-key
                <Collapse in={open} key={`error-code-row-${rowNumber}-game-${index}`}>
                    <div id={`error-code-row-${rowNumber}`}>{x}</div>
                </Collapse>
            );
        })}
    </>
);

const ErrorCodesTable: FunctionComponent<{ errorCodes: ErrorCodeModel[] }> = ({ errorCodes }) => {
    const [collapseStateByRow, setCollapseStateByRow] = useState<boolean[]>([]);
    const [tableModel, setTableModel] = useState<DiceTableProps>({
        tableName: 'error-codes',
        headerNames: ['Error Code', 'Count', 'Date and Time', 'Game Played'],
        rows: [],
    });

    useEffect(() => {
        const initialState: boolean[] = errorCodes.map(() => false);

        setCollapseStateByRow(initialState);
    }, [errorCodes.length]);

    useEffect(() => {
        // eslint-disable-next-line arrow-body-style
        const createOpenToggleFunction = (indexChanged: number): ((isOpen: boolean) => void) => {
            return (isOpen: boolean): void => {
                setCollapseStateByRow(
                    collapseStateByRow.map((isRowOpen, index) =>
                        indexChanged === index ? isOpen : isRowOpen,
                    ),
                );
            };
        };

        // Ensure that we're only displaying the 5 most recent unique Error Codes
        const mapToDiceTableRow = (
            errorCodeModel: ErrorCodeModel,
            index: number,
        ): DiceTableRowInterface => {
            const isOpen: boolean = collapseStateByRow[index];

            return {
                columns: [
                    errorCodeColumn(errorCodeModel, isOpen, createOpenToggleFunction(index), index),
                    countColumn(errorCodeModel),
                    datesOccurredColumn(errorCodeModel, isOpen, index),
                    gameUsedColumn(errorCodeModel, isOpen, index),
                ],
            };
        };

        const rows: DiceTableRowInterface[] =
            errorCodes.length !== collapseStateByRow.length
                ? []
                : errorCodes.slice(0, 5).map(mapToDiceTableRow);

        setTableModel({ ...tableModel, rows });
    }, [collapseStateByRow]);

    return (
        <DiceTable
            tableName={tableModel.tableName}
            headerNames={tableModel.headerNames}
            headersToHideInSmallMode={tableModel.headersToHideInSmallMode}
            rows={tableModel.rows}
        />
    );
};

export default ErrorCodesTable;
