import { HyruleToken } from '@nintendo/hyrule-react-commons';
import { BaseApi } from './BaseApi';
import { GameRecommendationApiModel } from './models';
import { GameRecommendationEntryModel } from '../components/account/game-recommendations/GameRecommendationEntryModel';

export class GameRecommendationsApi {
    static async getGameRecommendations(
        naid: string,
        token: HyruleToken,
        correlationId: string,
    ): Promise<GameRecommendationApiModel> {
        const { accountApiUrls } = BaseApi.ApiConfig();
        const url: string = accountApiUrls.gameRecommendations(naid);
        const init: RequestInit = await BaseApi.MulesoftRequestInit('GET', token, correlationId);

        try {
            const response: Response = await fetch(url, init);
            let model: GameRecommendationApiModel = { recommendations: [] };

            // If we're able to parse valid JSON from the response, do it.
            // Otherwise, if it's something like an HTTP 204 No Content and
            // it throws an error, then catch it and just set the array empty.
            await response
                .json()
                .then((json) => {
                    model = json || { recommendations: [] };
                })
                .catch(() => {
                    model = { recommendations: [] };
                });

            return model;
        } catch (error) {
            console.log(`Error: ${error}`);
            throw error;
        }
    }

    static mapToGameRecommendationEntryModels(
        model: GameRecommendationApiModel,
    ): GameRecommendationEntryModel[] {
        return model.recommendations.map(
            (gameRecommendationApiModel) =>
                ({
                    nsuid: gameRecommendationApiModel.nsuid,
                    imageUrl: gameRecommendationApiModel.image_url,
                    title: gameRecommendationApiModel.title,
                } as GameRecommendationEntryModel),
        );
    }
}
