import './Header.scss';
import React, { FunctionComponent } from 'react';
import { HasScopes } from '@nintendo/hyrule-react-commons';
import HeaderSearchFields from './HeaderSearchFields';
import { validDiceScopes } from '../shared/ScopeUtils';
import WarpzoneLogo from '../assets/warpzone-logo.png';

const Header: FunctionComponent = () => (
    <HasScopes scopes={validDiceScopes}>
        <div className="header-component" data-cy="header-component">
            <div className="row">
                <div className="col-9">
                    <HeaderSearchFields />
                </div>
                <div className="col-auto pb-2">
                    <img className="warpzone-logo-image" src={WarpzoneLogo} alt="Warpzone Logo" />
                </div>
            </div>
        </div>
    </HasScopes>
);

export default Header;
