import React, { useState, useRef, FunctionComponent } from 'react';
import { Overlay, Popover } from 'react-bootstrap';
import { ReactComponent as ArrowRightIcon } from '../../../../assets/arrow-up-right-from-square.svg';
import { ReactComponent as CloseIcon } from '../../../../assets/xmark.svg';
import { CopyButton } from '../../../../shared/CopyButton';
import { OrderSubType } from '../../../account/orders/types';
import { getOrderHrefLink } from '../../orders/getOrderHrefLink';
import { getOrderSource } from '../../orders/getOrderSource';
import { getOrderType } from '../../orders/getOrderType';
import './EShopOrderOverlay.scss';

interface EShopOrderOverlayProps {
    orderNumber: string;
    subType: OrderSubType;
    channel: string;
    contentType: number;
    children: JSX.Element;
}

const EShopOrderOverlay: FunctionComponent<EShopOrderOverlayProps> = ({
    orderNumber,
    subType,
    channel,
    contentType,
    children,
}) => {
    const target = useRef(null);
    const [show, setShow] = useState<boolean>(false);
    const type = getOrderType(contentType);

    return (
        <>
            <Overlay
                target={target.current}
                rootClose
                placement="right-start"
                show={show}
                onHide={() => {
                    setShow(false);
                }}
            >
                <div className="eshop-order-overlay">
                    <Popover id="repair-order">
                        <Popover.Title as="h3">
                            <div className="eshop-order-overlay__header">
                                <div>Order Information</div>
                                <button
                                    type="button"
                                    className="btn"
                                    onClick={() => setShow(false)}
                                >
                                    <CloseIcon className="eshop-order-overlay__close-icon" />
                                </button>
                            </div>
                        </Popover.Title>
                        <Popover.Content>
                            <dl className="eshop-order-overlay__grid">
                                <dt className="eshop-order-overlay__label">Order #:</dt>
                                <dd className="eshop-order-overlay__info">
                                    <a
                                        href={getOrderHrefLink(subType, orderNumber)}
                                        target="_blank"
                                        rel="noreferrer"
                                    >
                                        {orderNumber}
                                    </a>
                                    <div className="eshop-order-overlay__copy-icon">
                                        {orderNumber && (
                                            <CopyButton
                                                data-testid="copy-order-number"
                                                textToCopy={orderNumber}
                                                title={`Copy ${orderNumber} to clipboard.`}
                                                id={`repair-copy-${orderNumber}`}
                                            />
                                        )}
                                    </div>
                                    <ArrowRightIcon className="eshop-order-overlay__arrow-icon" />
                                </dd>

                                <dt className="eshop-order-overlay__label">Source:</dt>
                                <dd className="eshop-order-overlay__info">
                                    {getOrderSource(subType, channel)}
                                </dd>
                                {type && (
                                    <>
                                        <dt className="eshop-order-overlay__label">Type:</dt>
                                        <dd className="eshop-order-overlay__info">{type}</dd>
                                    </>
                                )}
                            </dl>
                        </Popover.Content>
                    </Popover>
                </div>
            </Overlay>
            <button
                data-testid="eshop-overlay-button"
                type="button"
                className="btn btn-link repairs-table-popover-trigger"
                ref={target}
                onClick={() => setShow(true)}
            >
                {children}
            </button>
        </>
    );
};

export default EShopOrderOverlay;
