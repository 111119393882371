import dayjs from 'dayjs';
import { ShippingLabel } from '../api/models';

const sources = ['Mize', 'ATG'];
type RepairSource = typeof sources[number] | undefined;

export interface RepairModel {
    orderNumber: number;
    status: string;
    dateSetup: dayjs.Dayjs | undefined;
    country: string;
    dateReceived: dayjs.Dayjs | undefined;
    dateShipped: dayjs.Dayjs | undefined;
    item: string;
    source: RepairSource;
    inboundLabels: ShippingLabel[];
    outboundLabels: ShippingLabel[];
}

export const validateSource = (source: unknown): source is RepairSource =>
    typeof source === 'string' && sources.includes(source);
