import { HyruleToken } from '@nintendo/hyrule-react-commons';
import { EShopApiTransactionsResponse } from './models/EShopApiModels';
import { HttpStatusCode } from '../shared/HttpStatusCode';
import { BaseApi } from './BaseApi';

export class EShopApi {
    static async getPurchaseHistory(
        naid: string,
        token: HyruleToken,
        correlationId: string,
        cursorNext?: string,
    ): Promise<EShopApiTransactionsResponse | null> {
        const { eShopApiUrls } = BaseApi.ApiConfig();
        let url: string = eShopApiUrls.purchaseHistory(naid);

        try {
            if (cursorNext) {
                url += `&cursor_next=${cursorNext}`;
            }

            const init: RequestInit = await BaseApi.MulesoftRequestInit(
                'GET',
                token,
                correlationId,
            );
            const response: Response = await fetch(url, init);

            if (!response.ok && response.status !== HttpStatusCode.NotFound) {
                throw new Error('Error getting eshop purchase history!');
            }
            return response.ok ? await response.json() : null;
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}
