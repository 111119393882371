import dayjs from 'dayjs';
import { HyruleToken } from '@nintendo/hyrule-react-commons';
import {
    AccountByNaidApiModel,
    AccountDetailsApiModel,
    AccountDeviceDetailsApiModel,
} from './models';
import { AutoRenewal, BanStatus, DeviceType } from '../components/shared/models/SharedEnums';
import { AccountDeviceDetailsTableEntryModel } from '../components/account/account-device-details/AccountDeviceDetailsTable';
import { BaseApi } from './BaseApi';
import Utils from '../shared/Utils';
import { AccountDetailsInformation } from '../components/account/account-details/AccountDetailsInformation';

export class AccountApi {
    static async getAccountInformationByNAID(
        naid: string,
        token: HyruleToken,
        correlationId: string,
    ): Promise<AccountByNaidApiModel> {
        const { accountApiUrls } = BaseApi.ApiConfig();
        const url: string = accountApiUrls.accountAndDeviceDetails(naid);
        const init: RequestInit = await BaseApi.MulesoftRequestInit('GET', token, correlationId);

        try {
            const response: Response = await fetch(url, init);
            return await response.json();
        } catch (error) {
            console.log(`Error: ${error}`);
            throw error;
        }
    }

    static mapToAccountDetailsModel(
        account: AccountDetailsApiModel,
        naid: string,
        email: string,
    ): AccountDetailsInformation {
        if (!account) {
            return {} as AccountDetailsInformation;
        }

        // TODO: are these spelled incorrectly because the Open API spec is? Fix it.
        const nsoExpiration: dayjs.Dayjs = dayjs(account.nso_expirataion);

        // Auto Renewal and Ban Status should either be true or false. If it's anything other than a valid boolean, then
        // we'll simply classify it as Blank and assign it as an empty string.
        let autoRenewal: AutoRenewal = AutoRenewal.Blank;
        let banStatus: BanStatus = BanStatus.Blank;
        const { ban_status, nso_auto_renewal } = account;
        if (!ban_status) {
            banStatus = BanStatus.NotBanned;
        } else if (ban_status) {
            banStatus = BanStatus.Banned;
        }
        if (!nso_auto_renewal) {
            autoRenewal = AutoRenewal.No;
        } else if (nso_auto_renewal) {
            autoRenewal = AutoRenewal.Yes;
        }

        return {
            accountId: naid,
            accountNickname: account.account_nickname,
            autoRenewal,
            avatarUrl: account.account_avatar_url,
            banStatus,
            email,
            nsaNickname: account.nsa_nickname,
            nsoStatus: account.nso_status,
            planExpiration: nsoExpiration,
        };
    }

    static mapToAccountDeviceDetailsTableEntryModel(
        devices: AccountDeviceDetailsApiModel[],
    ): AccountDeviceDetailsTableEntryModel[] {
        // If there are no associated devices for this account,
        // then the API seems to be returning null instead of [].
        if (!devices) {
            return [];
        }

        return devices.map((device) => {
            let { device_type } = device;
            const { serial_number, warranty_expiration } = device;
            const warrantyExpiration: dayjs.Dayjs = device.warranty_expiration
                ? dayjs(warranty_expiration)
                : (null as unknown as dayjs.Dayjs);

            // If the Serial Number starts with "XT", then we want to
            // display the Device Type as the Switch OLED model.
            if (serial_number?.startsWith('XT')) {
                device_type = DeviceType.SwitchOLED;
            }

            return {
                deviceNickname: BaseApi.UnmappedField,
                serialNumber: serial_number,
                type: device_type,
                warrantyStatus: Utils.expirationDate(warrantyExpiration),
                deviceViewUrl: `/device/${device.serial_number}`,
            } as AccountDeviceDetailsTableEntryModel;
        });
    }
}
