import { Col, Row } from 'react-bootstrap';
import React, { FunctionComponent, ReactElement, useContext } from 'react';
import { HyruleToken } from '@nintendo/hyrule-react-commons';
import CollapsiblePane from '../../shared/components/CollapsiblePane';
import { DeviceType } from '../../shared/models/SharedEnums';
import HyruleTokenContext from '../../contexts/HyruleTokenContext';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import NoDetailsFoundMessage from './NoDetailsFoundMessage';
import ServerErrorMessage from '../../shared/components/outline-messages/ServerErrorMessage';
import SwitchImageGrayPng from '../../../assets/switch-gray.png';
import SwitchImageGrayWebp from '../../../assets/switch-gray.webp';
import SwitchImageLightGrayPng from '../../../assets/switch-light-gray.png';
import SwitchImageLightGrayWebp from '../../../assets/switch-light-gray.webp';
import SwitchImageOledPng from '../../../assets/switch-oled.png';
import SwitchImageOledWebp from '../../../assets/switch-oled.webp';
import { SearchContext } from '../../contexts/SearchContext';
import { useDeviceDetails } from './useDeviceDetails';

interface DeviceDetailPairModel {
    isSmallMode?: boolean;
    label: string;
    value: string;
}

const DeviceDetailsPairLabel: FunctionComponent<DeviceDetailPairModel> = ({
    isSmallMode,
    label,
}) => (
    <Col
        xs={6}
        sm={3}
        className="mb-2 font-weight-bold"
        device-detail-label-for={!isSmallMode ? label : null}
        device-detail-small-label-for={isSmallMode ? label : null}
    >
        {label}:
    </Col>
);

const DeviceDetailsPairValue: FunctionComponent<DeviceDetailPairModel> = ({
    isSmallMode,
    label,
    value,
}) => (
    <Col
        xs={6}
        sm={3}
        className={`mb-2${value ? '' : ' font-italic'}`}
        device-detail-value-for={!isSmallMode ? label : null}
        device-detail-small-value-for={isSmallMode ? label : null}
    >
        {value || 'Unavailable'}
    </Col>
);

const DeviceDetailsPair: FunctionComponent<DeviceDetailPairModel> = (
    model: DeviceDetailPairModel,
) => (
    <>
        {DeviceDetailsPairLabel(model)}
        {DeviceDetailsPairValue(model)}
    </>
);

const DeviceDetails: FunctionComponent = () => {
    const searchContext = useContext(SearchContext);
    const serialNumber = searchContext?.resolvedSerialNumber || '';
    const correlationId = searchContext?.correlationId || '';
    const token: HyruleToken = useContext(HyruleTokenContext);

    const {
        loading,
        notFound,
        error,
        data: deviceDetails,
    } = useDeviceDetails(serialNumber, token, correlationId);

    const { deviceType, warrantyExpiration } = deviceDetails;

    // If there's no data loaded, then don't display any device image.
    // If there is data loaded, then first attempt to match it to an
    // existing Device Type. If it can't be matched to either a
    // Nintendo Switch Lite or a Nintendo Switch – OLED Model,
    // then simply default to a Nintendo Switch.
    let deviceImage: ReactElement | null = null;
    if (deviceType === DeviceType.SwitchLite) {
        deviceImage = (
            <picture>
                <source srcSet={SwitchImageLightGrayWebp} type="image/webp" />
                <img
                    src={SwitchImageLightGrayPng}
                    alt=""
                    id="device-details-switch-light-image"
                    height={97}
                    width={222}
                />
            </picture>
        );
    } else if (deviceType === DeviceType.SwitchOLED) {
        deviceImage = (
            <picture>
                <source srcSet={SwitchImageOledWebp} type="image/webp" />
                <img
                    src={SwitchImageOledPng}
                    alt=""
                    id="device-details-switch-oled-image"
                    height={97}
                    width={222}
                />
            </picture>
        );
    } else if (deviceType) {
        deviceImage = (
            <picture>
                <source srcSet={SwitchImageGrayWebp} type="image/webp" />
                <img
                    src={SwitchImageGrayPng}
                    alt=""
                    id="device-details-switch-image"
                    height={97}
                    width={222}
                />
            </picture>
        );
    }

    // Display data in one way in "small mode".
    // See grid breakpoints in CustomBootstrap.scss for more details.
    const smallModeContent: ReactElement = (
        <Row noGutters className="hide-in-normal-mode">
            <DeviceDetailsPair
                label="Serial Number"
                value={deviceDetails.serialNumber}
                isSmallMode
            />
            <DeviceDetailsPair label="Warranty Status" value={warrantyExpiration} isSmallMode />
            <DeviceDetailsPair label="Type" value={deviceType} isSmallMode />
        </Row>
    );

    // Display data in another way in "normal mode".
    // See grid breakpoints in CustomBootstrap.scss for more details.
    const normalModeContent: ReactElement = (
        <Row noGutters className="hide-in-small-mode">
            <DeviceDetailsPair label="Serial Number" value={deviceDetails.serialNumber} />
            <DeviceDetailsPair label="Warranty Status" value={warrantyExpiration} />
            <DeviceDetailsPair label="Type" value={deviceType} />
        </Row>
    );

    let paneContent: ReactElement = (
        <Row>
            <Col className="col-auto">{deviceImage}</Col>
            <Col>
                {smallModeContent}
                {normalModeContent}
            </Col>
        </Row>
    );

    // If an API error occurred, then display the error message.
    if (error) {
        paneContent = <ServerErrorMessage />;
    }

    // If no device was found for the specified serial number,
    // then display the not found message.
    if (notFound) {
        paneContent = <NoDetailsFoundMessage />;
    }

    const loadingSpinner: ReactElement = (
        <div className="text-center">
            <LoadingSpinner />
        </div>
    );

    return (
        <CollapsiblePane
            eventKey="DeviceDetails"
            headerText="Device Details"
            disablePaneContentPadding={error || notFound}
            dataCyForBody="device-device-details"
        >
            {loading && loadingSpinner}
            {!loading && paneContent}
        </CollapsiblePane>
    );
};

export default DeviceDetails;
