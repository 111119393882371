import './DiceTable.scss';
import React, { FunctionComponent, ReactElement } from 'react';
import dayjs from 'dayjs';
import Table from 'react-bootstrap/Table';
import Utils from '../../../shared/Utils';

export interface DiceTableRowInterface {
    columns: ReactElement[];
    columnsToHideInSmallMode?: number[];
}

export interface DiceTableProps {
    tableName: string;
    headerNames: string[];
    headersToHideInSmallMode?: number[];
    rows: DiceTableRowInterface[];
    noRowsMessage?: string;
}

export const unavailableText: ReactElement = <span className="font-italic">Unavailable</span>;
export const notApplicableText: ReactElement = <span>N/A</span>;

export const valueOrUnavailable = (value: string) => <span>{value || unavailableText}</span>;

export const formattedDateCell = (
    date: dayjs.Dayjs | undefined,
    useIfDataFalsy: ReactElement,
): ReactElement => {
    const formattedDate = Utils.formatDate(date);
    if (formattedDate) {
        return <div>{formattedDate}</div>;
    }
    return useIfDataFalsy;
};

const generateClassName = (hideInSmallMode: boolean): string | undefined =>
    hideInSmallMode ? 'hide-in-small-mode' : undefined;

const DiceTableHeaderCell = (
    name: string,
    index: number,
    hideInSmallMode: boolean,
): ReactElement => (
    <th key={`header-${index}`} className={generateClassName(hideInSmallMode)}>
        {name}
    </th>
);

const DiceTableHeaderRow = (
    headerNames: string[] = [],
    headersToHideInSmallMode: number[] = [],
): ReactElement => (
    <tr>
        {headerNames.map((headerName, index) =>
            DiceTableHeaderCell(headerName, index, !!headersToHideInSmallMode?.includes(index)),
        )}
    </tr>
);

const DiceTableCell = (
    element: ReactElement,
    index: number,
    hideInSmallMode: boolean,
): ReactElement => (
    <td key={`column-${index}`} className={generateClassName(hideInSmallMode)}>
        {element}
    </td>
);

const DiceTableRow = (
    { columns, columnsToHideInSmallMode }: DiceTableRowInterface,
    index: number,
): ReactElement => (
    <tr key={`row-${index}`}>
        {columns.map((x, index) =>
            DiceTableCell(x, index, !!columnsToHideInSmallMode?.includes(index)),
        )}
    </tr>
);

const FullColSpanMessage = (message: string | undefined): ReactElement => (
    <tr>
        <td colSpan={999}>
            <div className="text-center">{message || 'No records to found.'}</div>
        </td>
    </tr>
);

const DiceTable: FunctionComponent<DiceTableProps> = ({
    tableName,
    headerNames,
    headersToHideInSmallMode,
    rows,
    noRowsMessage,
}) => (
    <Table title={tableName} className="dice-table" bordered>
        <thead>{DiceTableHeaderRow(headerNames, headersToHideInSmallMode)}</thead>
        <tbody>
            {rows.length > 0
                ? rows.map((row, index) => DiceTableRow(row, index))
                : FullColSpanMessage(noRowsMessage)}
        </tbody>
    </Table>
);

export default DiceTable;
