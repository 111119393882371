import React, { FunctionComponent, ReactElement } from 'react';
import { HasScopes } from '@nintendo/hyrule-react-commons';
import { validDiceScopes } from '../shared/ScopeUtils';

const Footer: FunctionComponent = () => {
    const mailToLink: ReactElement = (
        <a href="mailto:CS_UX_Feedback@noa.nintendo.com">CS_UX_Feedback@noa.nintendo.com</a>
    );

    return (
        <HasScopes scopes={validDiceScopes}>
            <div className="text-center" data-cy="footer-component">
                <div>Do you have any feedback?</div>
                <div>Reach out to {mailToLink} and let us know.</div>
            </div>
        </HasScopes>
    );
};

export default Footer;
