import dayjs from 'dayjs';
import { HyruleToken } from '@nintendo/hyrule-react-commons';
import { BanStatus, DeviceType } from '../components/shared/models/SharedEnums';
import {
    DeviceAccountApiModel,
    DeviceAccountApiModelResponse,
    DeviceDetailsApiModel,
    DeviceDetailsApiModelResponse,
    DeviceRepairApiModel,
    DeviceRepairApiModelResponse,
    DeviceRepairEntryApiModel,
} from './models';
import { BaseApi } from './BaseApi';
import { HttpStatusCode } from '../shared/HttpStatusCode';

import Utils from '../shared/Utils';
import { AssociatedAccountModel } from '../components/device/associated-accounts/AssociatedAccountModel';
import { DeviceDetailsModel } from '../components/device/device-details/DeviceDetailsModel';
import { AssociatedAccountPriority } from '../components/device/associated-accounts/AssociatedAccountPriority';
import { RepairModel, validateSource } from '../types/RepairModel';

export const HoldStatusEmptyReasonMap: Map<string, string> = new Map(
    [
        ['CANCELLED', 'Cancelled'],
        ['Due In', 'Due In'],
        ['Repair in Progress', 'In Service'],
        ['Ready To Ship', 'Preparing to Ship'],
        ['Final Inspection', 'Preparing to Ship'],
        ['Transmitted', 'Due In'],
        ['Awaiting Tech', 'Received'],
        ['Recycled', 'Recycled'],
        ['Repair Payment Received', 'In Service'],
        ['Recycle Requested', 'Recycled'],
        ['Return Unrepaired No Response', 'Return Unrepaired - No Response Received'],
        ['Return Unrepaired Consumer Request', 'Return Unrepaired - Consumer Request'],
        ['Closed', 'Shipped'],
    ].map((x) => [x[0].toUpperCase(), x[1]]),
);

export const HoldStatusHoldMap: Map<string, string> = new Map(
    [
        ['Receipt Required', 'Receipt Required Hold'],
        ['Receipt Received', 'Received'],
        ['Additional Information Needed', 'Admin Hold'],
        ['PPI', 'PPI Hold'],
        ['Additional Components Required', 'Additional Components Hold'],
        ['Serial Number Mismatch', 'Serial Number Mismatch Hold'],
        ['Part Number Mismatch', 'Part Number Mismatch Hold'],
        ['Evaluation - Minor Repair', 'Evaluation Hold'],
        ['Evaluation - Major Repair', 'Evaluation Hold'],
        ['Payment Declined', 'Declined Payment Hold'],
        ['Mod/Tamp/Cfeit', 'Admin Hold'],
        ['Mod/Cfeit', 'Admin Hold'],
        ['Color Change', 'Color Change Hold'],
        ['Unauthorized Repair Center', 'Admin Hold'],
        ['Recycle', 'Recycle Hold'],
        ['Remove Shipping Chg', 'Remove Shipping Chg Hold'],
        ['Sister Unit', 'Sister Unit Hold'],
        ['Sister Unit On Hold', 'Sister Unit Hold'],
    ].map((x) => [x[0].toUpperCase(), x[1]]),
);

export class DeviceApi {
    static async getAccountsByDeviceSerialNumber(
        serialNumber: string,
        token: HyruleToken,
        correlationId: string,
    ): Promise<DeviceAccountApiModelResponse> {
        const { deviceApiUrls } = BaseApi.ApiConfig();
        const url: string = deviceApiUrls.associatedAccounts(serialNumber);
        const init: RequestInit = await BaseApi.MulesoftRequestInit('GET', token, correlationId);

        // Use cases of interest
        // 1. It passes and we get the associated accounts (or HTTP 200 OK with data).
        // 2. No results were found for the given serial number (or HTTP 204, 404, 200 with no data).
        // 3. Any other error.
        try {
            // Make the API call and parse the response. If the response is falsy, then we'll map an empty object.
            const response: Response = await fetch(url, init);

            let accounts: DeviceAccountApiModel[] = [] as DeviceAccountApiModel[];
            if (response.status === 200) accounts = (await response.json()) || [];

            // If the array exists, is empty, and we get a successful response OR an HTTP 404 Not Found,
            // then ensure we indicate that we should be displaying the Not Found screen later.
            const displayNotFoundScreen: boolean =
                (accounts && !accounts.length && response.ok) ||
                response.status === HttpStatusCode.NotFound;

            // If the response is NOT ok and we're talking about something other than an HTTP 404 Not Found, then
            // ensure we indicate that we should be displaying the Server Error screen later.
            const displayServerErrorScreen: boolean =
                !response.ok && response.status !== HttpStatusCode.NotFound;

            return {
                accounts,
                notFound: displayNotFoundScreen,
                serverError: displayServerErrorScreen,
            } as DeviceAccountApiModelResponse;
        } catch (error) {
            console.error(`DeviceApi getAccountsByDeviceSerialnumber() Error: ${error}`);

            return {
                accounts: [],
                notFound: false,
                serverError: true,
            } as DeviceAccountApiModelResponse;
        }
    }

    static mapToAssociatedAccountModel(
        deviceAccountApiModel: DeviceAccountApiModel[],
    ): AssociatedAccountModel[] {
        return deviceAccountApiModel.map(
            (account: DeviceAccountApiModel): AssociatedAccountModel => ({
                accountBanStatus: account.ban_status ? BanStatus.Banned : BanStatus.NotBanned,
                email: account.email_address,
                priority:
                    account.priority === 'Primary'
                        ? AssociatedAccountPriority.Primary
                        : AssociatedAccountPriority.NonPrimary,
                nickname: account.account_nickname,
                nsaNickname: account.nsa_nickname,
                nsoStatus: account.nso_status,
                nintendoAccountId: account.naid,
            }),
        );
    }

    static async getDeviceDetails(
        serialNumber: string,
        token: HyruleToken,
        correlationId: string,
    ): Promise<DeviceDetailsApiModelResponse> {
        const { deviceApiUrls } = BaseApi.ApiConfig();
        const url: string = deviceApiUrls.deviceDetails(serialNumber);
        const init: RequestInit = await BaseApi.MulesoftRequestInit('GET', token, correlationId);

        try {
            // Make the API call and parse the response. If the response is falsy, then we'll map an empty object.
            const response = await fetch(url, init);
            let responseBody: DeviceDetailsApiModel = {} as DeviceDetailsApiModel;
            if (response.status === 200) responseBody = (await response.json()) || {};

            // Strongly type the response, deconstruct it, and validate it's what we expect. If
            // everything is falsy, then we'll indicate that all associated data is unavailable.
            const { device_type, purchase_date, serial_number, warranty_expiration } = responseBody;
            const allUnavailable: boolean =
                !device_type && !purchase_date && !serial_number && !warranty_expiration;

            // If all associated data is unavailable, and we get a successful response OR an HTTP 404 Not Found,
            // then ensure we indicate that we should be displaying the Not Found screen later.
            const displayNotFoundScreen: boolean =
                allUnavailable && (response.ok || response.status === HttpStatusCode.NotFound);

            // If the response is NOT ok, and we're talking about something other than an HTTP 404 Not Found, then
            // ensure we indicate that we should be displaying the Server Error screen later.
            const displayServerErrorScreen: boolean =
                !response.ok && response.status !== HttpStatusCode.NotFound;

            return {
                notFound: displayNotFoundScreen,
                serverError: displayServerErrorScreen,
                deviceDetails: responseBody,
            } as DeviceDetailsApiModelResponse;
        } catch (error) {
            console.error(`DeviceApi getDeviceDetails() Error: ${error}`);

            return {
                notFound: false,
                serverError: true,
                deviceDetails: {} as DeviceDetailsApiModel,
            } as DeviceDetailsApiModelResponse;
        }
    }

    static mapToDeviceDetailsModel(model: DeviceDetailsApiModel): DeviceDetailsModel {
        if (!model) {
            return {} as DeviceDetailsModel;
        }

        let { device_type } = model;
        const { serial_number, warranty_expiration } = model;
        const serialNumberUppercase: string = serial_number
            ? serial_number.toUpperCase()
            : serial_number;
        const warrantyExpiration: dayjs.Dayjs = warranty_expiration
            ? dayjs(warranty_expiration)
            : (null as unknown as dayjs.Dayjs);

        // If the Serial Number starts with "XT", then we want to
        // display the Device Type as the Switch OLED model.
        if (serial_number?.startsWith('XT')) {
            device_type = DeviceType.SwitchOLED;
        }

        return {
            deviceType: device_type,
            serialNumber: serialNumberUppercase,
            warrantyExpiration: Utils.expirationDate(warrantyExpiration),
        };
    }

    static async getDeviceRepairsByEmail(
        email: string,
        token: HyruleToken,
        correlationId: string,
    ): Promise<DeviceRepairApiModelResponse> {
        const { deviceApiUrls } = BaseApi.ApiConfig();
        const url: string = deviceApiUrls.deviceRepairsByEmail(encodeURIComponent(email));
        const init: RequestInit = await BaseApi.MulesoftRequestInit('GET', token, correlationId);

        const emptyResponse: DeviceRepairApiModel = {
            repairs: [],
        };

        try {
            // Make the API call and parse the response.
            const response: Response = await fetch(url, init);
            let model: DeviceRepairApiModel = emptyResponse;
            if (response.status === 200) model = (await response.json()) || emptyResponse;

            // If there are no Repairs, then ensure we indicate that we should be displaying the Not Found screen later.
            const notFound: boolean =
                (response.ok && !model.repairs.length) ||
                response.status === HttpStatusCode.NotFound;

            // If the response is NOT ok, and we're talking about something other than an HTTP 404 Not Found, then
            // ensure we indicate that we should be displaying the Server Error screen later.
            const serverError: boolean =
                !response.ok && response.status !== HttpStatusCode.NotFound;

            return {
                notFound,
                serverError,
                model,
            } as DeviceRepairApiModelResponse;
        } catch (error) {
            console.error(`PartOrderApi getDeviceRepairs() Error: ${error}`);

            return {
                notFound: false,
                serverError: true,
                model: emptyResponse,
            } as DeviceRepairApiModelResponse;
        }
    }

    static async getDeviceRepairs(
        serialNumber: string,
        token: HyruleToken,
        correlationId: string,
    ): Promise<DeviceRepairApiModelResponse> {
        const { deviceApiUrls } = BaseApi.ApiConfig();
        const url: string = deviceApiUrls.deviceRepairs(serialNumber);
        const init: RequestInit = await BaseApi.MulesoftRequestInit('GET', token, correlationId);
        const isEmptyResponse = (model: DeviceRepairApiModel): boolean =>
            !model || !model.repairs || !model.repairs.length;

        const emptyResponse: DeviceRepairApiModel = {
            repairs: [],
        };

        try {
            // Make the API call and parse the response.
            const response: Response = await fetch(url, init);

            // If the response.json() call fails (because it's not valid JSON and
            // is falsy, text, etc) for any reason, then simply map an empty array.
            let model: DeviceRepairApiModel;
            try {
                const jsonResponse: DeviceRepairApiModel = await response.json();
                model = isEmptyResponse(jsonResponse) ? emptyResponse : jsonResponse;
            } catch {
                model = emptyResponse;
            }

            // If there are no Repairs, then ensure we indicate that we should be displaying the Not Found screen later.
            const responseOkWithNoOrders: boolean = response.ok && !model.repairs.length;
            const responseNotFound: boolean = response.status === HttpStatusCode.NotFound;
            const displayNotFoundScreen: boolean = responseOkWithNoOrders || responseNotFound;

            // If the response is NOT ok, and we're talking about something other than an HTTP 404 Not Found, then
            // ensure we indicate that we should be displaying the Server Error screen later.
            const displayServerErrorScreen: boolean =
                !response.ok && response.status !== HttpStatusCode.NotFound;

            return {
                notFound: displayNotFoundScreen,
                serverError: displayServerErrorScreen,
                model,
            } as DeviceRepairApiModelResponse;
        } catch (error) {
            console.error(`PartOrderApi getDeviceRepairs() Error: ${error}`);

            return {
                notFound: false,
                serverError: true,
                model: emptyResponse,
            } as DeviceRepairApiModelResponse;
        }
    }

    static mapToRepairModels(repairs: DeviceRepairEntryApiModel[]): RepairModel[] {
        if (!repairs) {
            return [];
        }

        return repairs.map((repairEntry: DeviceRepairEntryApiModel) => ({
            orderNumber: repairEntry.order_number,
            status: DeviceApi.mapStatus(repairEntry),
            dateSetup: DeviceApi.parseDayJSTreatingUndefinedAsInvalid(repairEntry.date_setup),
            dateReceived: DeviceApi.parseDayJSTreatingUndefinedAsInvalid(repairEntry.date_received),
            dateShipped: DeviceApi.parseDayJSTreatingUndefinedAsInvalid(repairEntry.date_shipped),
            item: repairEntry.item,
            country: repairEntry.country,
            source: validateSource(repairEntry.source) ? repairEntry.source : undefined,
            inboundLabels: repairEntry.inboundLabels,
            outboundLabels: repairEntry.outboundLabels,
        }));
    }

    private static UnavailableStatus = 'Unavailable';

    static mapStatus(repairEntry: DeviceRepairEntryApiModel): string {
        if (!repairEntry.status) return this.UnavailableStatus;

        if (!repairEntry.hold_reason) {
            return (
                HoldStatusEmptyReasonMap.get(repairEntry.status.toUpperCase()) || repairEntry.status
            );
        }
        if (repairEntry.status === 'Hold') {
            return (
                HoldStatusHoldMap.get(repairEntry.hold_reason.toUpperCase()) || repairEntry.status
            );
        }

        return repairEntry.status;
    }

    static parseDayJSTreatingUndefinedAsInvalid(
        dateString: string | undefined,
    ): dayjs.Dayjs | undefined {
        return dateString ? dayjs(dateString) : undefined;
    }
}
