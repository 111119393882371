import './CollapsiblePane.scss';
import React, { FunctionComponent, ReactElement } from 'react';
import Accordion from 'react-bootstrap/Accordion';
import Card from 'react-bootstrap/Card';
import CopyToClipboardWithLogoButton, {
    CopyToClipboardWithLogoButtonProps,
} from './CopyToClipboardWithLogoButton';

interface CollapsiblePaneProps {
    eventKey: string;
    headerText: string;
    copyToClipboardButtons?: CopyToClipboardWithLogoButtonProps[];
    disablePaneContentPadding?: boolean;
    dataCyForBody?: string;
    additionalHeaderElement?: ReactElement;
}

const CollapsiblePane: FunctionComponent<CollapsiblePaneProps> = ({
    eventKey,
    headerText,
    copyToClipboardButtons = [],
    disablePaneContentPadding = false,
    dataCyForBody = null,
    additionalHeaderElement = undefined,
    children,
}) => (
    <Accordion defaultActiveKey={eventKey} data-cy="collapsible-pane-component">
        <Card>
            <Card.Header className="collapsible-pane-header">
                <div className="d-flex align-items-center collapsible-pane-heading-container">
                    <h6 className="m-0" data-cy={headerText}>
                        {headerText}
                    </h6>
                    {copyToClipboardButtons?.map((button) => (
                        <CopyToClipboardWithLogoButton
                            key={button.id}
                            id={button.id}
                            textToCopy={button.textToCopy}
                            imgAlt={button.imgAlt}
                            imgSrc={button.imgSrc}
                        />
                    ))}
                </div>
                <div>{additionalHeaderElement}</div>
            </Card.Header>
            <Accordion.Collapse className="dice-accordion-collapse" eventKey={eventKey}>
                <Card.Body
                    className={disablePaneContentPadding ? 'p-0' : ''}
                    data-cy={dataCyForBody}
                >
                    {children}
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    </Accordion>
);

export default CollapsiblePane;
