import { useEffect, useState } from 'react';
import { HyruleToken } from '@nintendo/hyrule-react-commons';
import { GamePlayDataModel } from './GamePlayDataModel';
import Utils from '../../../shared/Utils';
import { GameSummaryApiModel } from '../../../api/models';
import { GamePlaySummaryApi } from '../../../api';

interface GamePlayHookData {
    data: GamePlayDataModel[];
    loading: boolean;
    error: boolean;
}

export const useGamePlay = (
    naid: string,
    token: HyruleToken,
    correlationId: string,
): GamePlayHookData => {
    const [data, setData] = useState<GamePlayDataModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const getGamePlayData = async (): Promise<void> => {
        setLoading(true);
        try {
            const gameSummaryApiModel: GameSummaryApiModel =
                await GamePlaySummaryApi.getGameHistorySummaries(naid, token, correlationId);
            setData(GamePlaySummaryApi.mapToGamePlayDataModelList(gameSummaryApiModel));
            setError(false);
        } catch (error) {
            setData([]);
            setError(true);
        }
        setLoading(false);
    };

    useEffect(() => {
        if (Utils.isValidNAID(naid) && token.accessToken) {
            getGamePlayData();
        } else {
            setData([]);
        }
    }, [naid, token]);

    return {
        data,
        loading,
        error,
    };
};
