// Disable this rule for this file to prevent false positives on valid Regex sytax.
/* eslint-disable no-useless-escape */
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration.js';
import { DateFormat } from './DateFormat';

class Utils {
    /**
     * @summary Determine whether or not a string is null, undefined,
     * or whitespace. This is akin to the C# string.isNullorWhitespace()
     * function.
     * @returns Is the specified input falsy string (like null or undefined)
     * or all whitespace characters?
     */
    static isNullOrWhitespace(s: string): boolean {
        return !s || s.match(/^\s*$/) !== null;
    }

    /**
     * @summary Determine whether a specified email is valid or not.
     * @returns Is the specified input a valid email?
     */
    static isValidEmail(email: string): boolean {
        const emailRegex =
            /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return emailRegex.test(email);
    }

    /**
     * @summary A valid serial number is defined as exactly 3 letters
     * followed by 11 numbers.
     * @returns Is the specified input a valid serial number?
     */
    static isValidSerialNumber(serialNumber: string): boolean {
        const serialNumberRegex = /^[a-zA-Z]{3}\d{11}$/;
        return serialNumberRegex.test(serialNumber);
    }

    /**
     * @summary A valid NAID is defined as a 16 character alphanumeric
     * set of characters.
     * @returns Is the specified input a NAID?
     */
    static isValidNAID(naid: string): boolean {
        const naidRegex = /^[a-zA-Z0-9]{16}$/;
        return naidRegex.test(naid);
    }

    static isValidEmailOrNaid(maybeEmailOrNaid: string): boolean {
        return Utils.isValidEmail(maybeEmailOrNaid) || Utils.isValidNAID(maybeEmailOrNaid);
    }

    /**
     * @summary Takes any number of seconds and neatly breaks it down into hours and minutes.
     * @returns The number of specified seconds in terms of hours and minutes.
     */
    static secondsToHoursAndMinutes(seconds: number): { hours: number; minutes: number } {
        dayjs.extend(duration);
        const hours: number = Math.floor(dayjs.duration(seconds, 'seconds').asHours());
        const minutes: number = dayjs.duration(seconds, 'seconds').minutes();
        return { hours, minutes };
    }

    /**
     * @summary Takes an Expiration Date as a DayJS in time and represents
     * it as either a Valid Expiration Date, Expired Expiration Date, or blank
     * if there is no data available for it.
     * @returns A string representation of a specified Expiration Date.
     */
    static expirationDate(expDate: dayjs.Dayjs, format = DateFormat.date): string {
        // Establish the current date/time (i.e. right now).
        const now: dayjs.Dayjs = dayjs();

        // If there's no expiration date or it's invalid, then display blank.
        if (!expDate || !expDate.isValid()) {
            return '';
        }

        // After we've established that this is a valid DayJS, define this once so
        // that we don't have to repeat ourselves.
        const expDateStr = `${expDate.format(format)}`;

        // If the expiration date is in the past, then display "Expired (expiration date)".
        if (expDate.isBefore(now)) {
            return `Expired (${expDateStr})`;
            // If the expiration date is now or in the future, then display "Valid (expiration date)".
        }
        return `Valid (${expDateStr})`;
    }

    /**
     * Code Reference: https://stackoverflow.com/questions/400212/how-do-i-copy-to-the-clipboard-in-javascript
     *
     * Copies the specified text to the clipboard.
     * @param text The text to copy to the clipboard.
     */
    static copyToClipboard(text: string): void {
        // The TypeScript types don't seem to contain clipboardData even though it
        // exists for some browsers. Therefore, we'll simply hard cast this.
        // eslint-disable-next-line
        const windowObj: any = <any>window;

        if (windowObj.clipboardData && windowObj.clipboardData.setData) {
            // Internet Explorer specific code path to prevent textarea being shown while dialog is visible.
            windowObj.clipboardData.setData('Text', text);
        } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
            // Create a hidden text area element that we'll simply use as a place to dump text within the DOM.
            const textArea = document.createElement('textarea');
            // Assign the text the function specified to this text area's text content.
            textArea.textContent = text;
            // Prevent scrolling to bottom of page in Microsoft Edge.
            textArea.style.position = 'fixed';
            // Add the new <textarea> element to document body.
            document.body.appendChild(textArea);
            // Select all text within the newly added <textarea> element.
            textArea.select();
            try {
                // A security exception may be thrown by some browsers.
                document.execCommand('copy');
            } catch (e) {
                console.error('Copy to clipboard failed!', e);
            } finally {
                // Remove the <textarea> element after we're done so
                // it's not just hanging out in the DOM doing nothing.
                document.body.removeChild(textArea);
            }
        }
    }

    static formatDate = (date: dayjs.Dayjs | undefined): string | null => {
        if (date?.isValid?.()) {
            const parsed = dayjs(date);
            if (parsed.isValid()) {
                return parsed.format(DateFormat.date);
            }
        }

        return null;
    };

    static searchFields = (searchTerm: string, values: unknown[]): boolean =>
        values.some((value) => Utils.searchField(searchTerm, value));

    static searchField = (searchTerm: string, value: unknown | undefined): boolean =>
        value ? value.toString().search(new RegExp(searchTerm, 'ig')) > -1 : false;
}

export default Utils;
