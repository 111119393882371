import { useQuery } from 'react-query';
import { HyruleToken } from '@nintendo/hyrule-react-commons';
import dayjs from 'dayjs';
import { EShopApiTransactionsResponse } from '../../../api/models';
import { EShopApi } from '../../../api';
import { OrderType, OrderSubType } from '../orders/types';
import { OrdersHookData } from './types';

export const useEShopOrders = (
    naid: string,
    token: HyruleToken,
    correlationId: string,
): OrdersHookData => {
    const { data, isLoading, isRefetching, isError, refetch } =
        useQuery<EShopApiTransactionsResponse | null>(
            ['eshop-orders', naid],
            async () => {
                if (naid && correlationId && token)
                    return EShopApi.getPurchaseHistory(naid, token, correlationId);

                return Promise.resolve(null);
            },
            {
                cacheTime: 1000 * 60 * 10, // TODO what is our TTL?
            },
        );

    const orders =
        data?.documents?.map((transaction) => ({
            type: OrderType.DIGITAL,
            subType: OrderSubType.ESHOP,
            id: transaction.transaction_id,
            country: transaction.country,
            contentType: transaction.content_type,
            channel: transaction.transaction_channel,
            date: dayjs(transaction.transaction_date),
            items: [
                {
                    description: transaction.content_name,
                },
            ],
        })) || [];

    return {
        orders,
        isLoading: isLoading || isRefetching,
        isNotFound: !isError && orders.length === 0,
        isError,
        refetch,
    };
};
