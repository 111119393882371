// These must be the first lines in src/index.js per the instructions in the create-react-app docs:
// https://github.com/facebook/create-react-app/blob/master/packages/react-app-polyfill/README.md#polyfilling-other-language-features
// eslint-disable-next-line import/no-unresolved
import 'react-app-polyfill/ie11';
// eslint-disable-next-line import/no-unresolved
import 'react-app-polyfill/stable';
import 'formdata-polyfill'; // ie11 FormData support
// eslint-disable-next-line import/no-unresolved
import 'core-js/stable';

// Import Bootstrap.
import './App.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
import App from './App';
import DiceEnvironmentConfig from './environment/config';

DiceEnvironmentConfig.setupHyruleAuthServerConfigOnWindow();

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
