import { HyruleToken } from '@nintendo/hyrule-react-commons';
import { useEffect, useState } from 'react';
import { ErrorCodeModel } from './ErrorCodeModel';
import Utils from '../../../shared/Utils';
import { ErrorCodeApiModelResponse } from '../../../api/models';
import { ErrorCodeApi } from '../../../api';

interface ErrorCodesHookData {
    data: ErrorCodeModel[];
    loading: boolean;
    notFound: boolean;
    error: boolean;
}

export const useErrorCodes = (
    serialNumber: string,
    token: HyruleToken,
    correlationId: string,
): ErrorCodesHookData => {
    const [data, setData] = useState<ErrorCodeModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [notFound, setNotFound] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const getErrorCodes = async (): Promise<void> => {
        setLoading(true);
        const errorCodesResponse: ErrorCodeApiModelResponse = await ErrorCodeApi.getErrorCodes(
            serialNumber,
            token,
            correlationId,
        );

        const { notFound, serverError, model } = errorCodesResponse;

        setError(serverError);
        setNotFound(notFound);
        setData(ErrorCodeApi.mapToErrorCodeModels(model));
        setLoading(false);
    };

    useEffect(() => {
        if (Utils.isValidSerialNumber(serialNumber) && token.accessToken) {
            getErrorCodes();
        } else {
            setData([]);
        }
    }, [serialNumber, token]);

    return { data, loading, notFound, error };
};
