import './NoAccess.scss';
import { AuthUtils, UserContext } from '@nintendo/hyrule-react-commons';
import { Button, Card, Col, Row } from 'react-bootstrap';
import React, { FunctionComponent, useContext } from 'react';
import HyruleTokenContext from '../../../contexts/HyruleTokenContext';

const idrpUrl: string =
    'https://noa.service-now.com/com.glideapp.servicecatalog_cat_item_view.do?' +
    'v=1&sysparm_id=a7cc3688138f5b40da01b9722244b061&sysparm_preview=true&sysparm_domain_restore=false&sysparm_stack=no';

const NoAccess: FunctionComponent = () => {
    const userContext = useContext(UserContext);
    const { accessToken } = useContext(HyruleTokenContext);

    const navToServiceNow = (): void => {
        window.location.href = idrpUrl;
    };

    const navToHyruleLogout = (): void => {
        AuthUtils.logoutRedirect(accessToken);
    };

    return (
        <div data-cy="no-access-component container" className="no-access-component text-center">
            <Row>
                <Col>
                    <h4 className="page-title">Trouble Logging in?</h4>
                </Col>
            </Row>
            <Row className="align-items-center justify-content-center">
                <div className="col line">
                    <Card className="no-access ml-auto">
                        <Card.Body className="d-flex flex-column">
                            <Card.Title>
                                <span>New to WarpZone</span>
                            </Card.Title>
                            <Card.Text>
                                <p>
                                    If you haven't used WarpZone before, please request access using
                                    Service Now.
                                </p>
                            </Card.Text>
                            <Button className="mt-auto" onClick={navToServiceNow}>
                                Request Access
                            </Button>
                        </Card.Body>
                    </Card>
                </div>
                <div className="col">
                    <Card className="no-access">
                        <Card.Body className="d-flex flex-column">
                            <Card.Title>
                                <span>Incorrect Login</span>
                            </Card.Title>
                            <Card.Text>
                                <p>It's possible you are logged in to the incorrect account.</p>
                                <p>Please log out and log in using your NOA email address.</p>
                                <p className="mt-2">
                                    <span className="font-weight-bold">Current Account: </span>
                                    {userContext?.userId}
                                </p>
                            </Card.Text>
                            <Button className="mt-auto" onClick={navToHyruleLogout}>
                                Log Out
                            </Button>
                        </Card.Body>
                    </Card>
                </div>
            </Row>
        </div>
    );
};

export default NoAccess;
