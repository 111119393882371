import { OrderSubType } from '../../account/orders/types';

export const getOrderSource = (subType: OrderSubType, channel: string): string => {
    if (subType === OrderSubType.ServiceOrderStoreMagento) {
        return 'Service Order Store';
    }

    if (subType === OrderSubType.MyNintendoStorePhysical) {
        return 'My Nintendo Store';
    }

    if (channel.toUpperCase() === 'OFFDEVICE') {
        return 'eShop (off device)';
    }

    if (channel.toUpperCase() === 'HAC') {
        return 'eShop (on device)';
    }

    if (channel.toUpperCase() === 'SYSTEM') {
        return 'eShop (auto renewal)';
    }

    return '';
};
