import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { GamePlayDataModel } from './GamePlayDataModel';
import './GamePlayHistoryList.scss';
import { resizeImageUrl } from '../../../shared/CdnImages';
import NoCoverImage from '../../../assets/no-cover-vertical.svg';
import GamePlayHistoryDetailsPopover from './GamePlayHistoryDetailsPopover';
import { Pagination, PaginationInterface } from '../../shared/components/pagination/Pagination';
import { SearchField } from '../../shared/components/search-field/SearchField';
import Utils from '../../../shared/Utils';

interface GamePlayHistoryListProps {
    games: GamePlayDataModel[];
}

export const GamePlayHistoryList: FunctionComponent<GamePlayHistoryListProps> = ({ games }) => {
    const [searchQuery, setSearchQuery] = useState<string>('');
    const [pagination, setPagination] = useState<PaginationInterface>({
        totalPages: 1,
        currentPage: 1,
        pageSize: 5,
        numItems: 0,
    });

    const filteredGames = useMemo(
        () => games.filter((game) => Utils.searchFields(searchQuery, [game.title])),
        [searchQuery],
    );
    const pageStartIndex = (pagination.currentPage - 1) * pagination.pageSize;
    const pageEndIndex = pageStartIndex + pagination.pageSize;
    const gamesOnPage = filteredGames.slice(pageStartIndex, pageEndIndex);

    useEffect(() => {
        setPagination({
            ...pagination,
            totalPages: Math.ceil(filteredGames.length / pagination.pageSize),
            currentPage: 1,
            numItems: filteredGames.length,
        });
    }, [filteredGames]);

    return (
        <div className="game-play-history-list p-3">
            <SearchField
                placeholder="Search game title"
                onQueryChange={(text) => setSearchQuery(text)}
            />
            <ol className="list-unstyled">
                {gamesOnPage.map((game) => (
                    <li className="game-play-history-list__item py-3 d-flex align-items-center">
                        <img
                            src={game.imageUrl ? resizeImageUrl(game.imageUrl, 140) : NoCoverImage}
                            alt=""
                            width="140"
                            height="80"
                        />
                        <div>{game.title}</div>
                        <div className="ml-auto">
                            <p className="m-0">
                                <strong>Play Time</strong>
                            </p>
                            <p className="mb-3">{game.playTime}</p>
                            <p className="m-0">
                                <GamePlayHistoryDetailsPopover
                                    applicationId={game.applicationId}
                                    consumerId={game.consumerId}
                                    buttonLabel="View Last 30 Days"
                                    daysAgo={30}
                                />
                            </p>
                        </div>
                    </li>
                ))}
            </ol>
            <Pagination pagination={pagination} updatePagination={setPagination} />
        </div>
    );
};
