import React, { FunctionComponent } from 'react';
import OutlineMessage from '../../shared/components/outline-messages/OutlineMessage';
import { ReactComponent as ShellOutlineImage } from '../../../assets/shell-outline.svg';

const AssociatedAccountsNoResultsMessage: FunctionComponent = () => (
    <OutlineMessage
        message="There currently aren't any accounts associated with this serial number."
        messageLocation="message-right"
        outlineImage={<ShellOutlineImage name="shell-outline-image" />}
    />
);

export default AssociatedAccountsNoResultsMessage;
