import { HyruleToken } from '@nintendo/hyrule-react-commons';
import { useEffect, useState } from 'react';
import { DeviceDetailsModel } from './DeviceDetailsModel';
import Utils from '../../../shared/Utils';
import { DeviceDetailsApiModel, DeviceDetailsApiModelResponse } from '../../../api/models';
import { DeviceApi } from '../../../api';

interface DeviceDetailsHookData {
    loading: boolean;
    error: boolean;
    notFound: boolean;
    data: DeviceDetailsModel;
}

export const useDeviceDetails = (
    serialNumber: string,
    token: HyruleToken,
    correlationId: string,
): DeviceDetailsHookData => {
    const [data, setData] = useState<DeviceDetailsModel>({} as DeviceDetailsModel);
    const [notFound, setNotFound] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const getDeviceDetails = async (): Promise<void> => {
        setLoading(true);

        const deviceDetailsResponse: DeviceDetailsApiModelResponse =
            await DeviceApi.getDeviceDetails(serialNumber, token, correlationId);

        const { notFound, serverError } = deviceDetailsResponse;

        setNotFound(notFound);
        setError(serverError);

        const deviceDetailsApiModel: DeviceDetailsApiModel = deviceDetailsResponse.deviceDetails;
        setData(DeviceApi.mapToDeviceDetailsModel(deviceDetailsApiModel));
        setLoading(false);
    };

    useEffect(() => {
        if (Utils.isValidSerialNumber(serialNumber) && token.accessToken) getDeviceDetails();
        else setData({} as DeviceDetailsModel);
    }, [serialNumber, token]);

    return { loading, error, notFound, data };
};
