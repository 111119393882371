import './OrdersTable.scss';

import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import DiceTable, {
    DiceTableRowInterface,
    formattedDateCell,
    notApplicableText,
    unavailableText,
    valueOrUnavailable,
} from '../../shared/components/DiceTable';
import OrderIcon from './OrderIcon';
import { OrderModel, OrderType } from './types';
import EShopOrderOverlay from '../../shared/components/eshop-order-overlay/EShopOrderOverlay';
import { RadioPill } from '../../shared/components/pill-button/RadioPill';
import Utils from '../../../shared/Utils';
import { Pagination, PaginationInterface } from '../../shared/components/pagination/Pagination';
import { SearchField } from '../../shared/components/search-field/SearchField';

const orderNumberColumn = (model: OrderModel): ReactElement => (
    <div className="d-flex justify-content-between align-items-center">
        {model.id ? (
            <EShopOrderOverlay
                subType={model.subType}
                orderNumber={model.id.toString()}
                channel={model.channel ?? ''}
                contentType={model.contentType ?? -1}
            >
                <span>{model.id}</span>
            </EShopOrderOverlay>
        ) : (
            unavailableText
        )}
        {model.id && <OrderIcon orderNumber={model.id} orderType={model.type} />}
    </div>
);

const dateShippedColumn = (order: OrderModel): ReactElement => (
    <div>
        {order.items?.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
                {formattedDateCell(
                    item.shipDate,
                    order.type === OrderType.PHYSICAL ? unavailableText : notApplicableText,
                )}
            </div>
        ))}
    </div>
);

const statusColumn = (order: OrderModel): ReactElement => (
    <div>
        {order.items?.map((item, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
                {item.orderStatus ||
                    (order.type === OrderType.PHYSICAL ? unavailableText : notApplicableText)}
            </div>
        ))}
    </div>
);

const itemsColumn = (model: OrderModel): ReactElement => {
    if (model?.items?.length) {
        return (
            <div>
                {model.items?.map((x, index) => (
                    // eslint-disable-next-line react/no-array-index-key
                    <div key={index}>{x.description || unavailableText}</div>
                ))}
            </div>
        );
    }
    return unavailableText;
};

export interface OrdersTableProps {
    orders: OrderModel[];
}

const OrdersTable: FunctionComponent<OrdersTableProps> = ({ orders }) => {
    const [searchTerm, setSearchTerm] = useState<string>('');
    const [filter, setFilter] = useState<string | null>('all');
    const [filteredOrders, setFilteredOrders] = useState<OrderModel[]>(orders);
    const [pagination, setPagination] = useState<PaginationInterface>({
        totalPages: 1,
        currentPage: 1,
        pageSize: 5,
        numItems: 0,
    });

    const pageStartIndex = (pagination.currentPage - 1) * pagination.pageSize;
    const pageEndIndex = pageStartIndex + pagination.pageSize;

    useEffect(() => {
        if (filter === 'all') {
            setFilteredOrders(orders.filter(applySearch));
        } else {
            setFilteredOrders(orders.filter(applyFilter).filter(applySearch));
        }
    }, [filter, searchTerm, orders]);

    useEffect(() => {
        setPagination({
            ...pagination,
            totalPages: Math.ceil(filteredOrders.length / pagination.pageSize),
            currentPage: 1,
            numItems: filteredOrders.length,
        });
    }, [filteredOrders]);

    const applyFilter = (order: OrderModel) => order.type === filter;
    const applySearch = (order: OrderModel) =>
        Utils.searchFields(searchTerm, [
            order.id,
            order.type,
            Utils.formatDate(order.date),
            order.country,
        ]) ||
        order.items.some((item) =>
            Utils.searchFields(searchTerm, [
                item.description,
                item.orderStatus,
                Utils.formatDate(item.shipDate),
            ]),
        );

    const count = (orderType: OrderType) =>
        orders.filter(applySearch).filter((order) => order.type === orderType).length;

    const rows: DiceTableRowInterface[] = filteredOrders
        .slice(pageStartIndex, pageEndIndex) // pagination
        .map((order) => ({
            columns: [
                orderNumberColumn(order),
                valueOrUnavailable(order.country),
                formattedDateCell(order.date, unavailableText),
                itemsColumn(order),
                statusColumn(order),
                dateShippedColumn(order),
            ],
        }));

    return (
        <div className="orders-table">
            <fieldset className="orders-table__filter">
                <legend className="orders-table__filter-label">
                    <strong className="mr-2 pt-1">Order Type:</strong>
                </legend>

                <RadioPill
                    id="orders-filter-all"
                    name="orders-filter"
                    className="mr-2"
                    value="all"
                    text="All"
                    active={filter === 'all'}
                    onChange={setFilter}
                />
                <RadioPill
                    id="orders-filter-eshop"
                    name="orders-filter"
                    className="mr-2"
                    value={OrderType.DIGITAL}
                    text={`Digital (${count(OrderType.DIGITAL)})`}
                    disabled={count(OrderType.DIGITAL) === 0}
                    active={filter === OrderType.DIGITAL}
                    onChange={setFilter}
                />
                <RadioPill
                    id="orders-filter-parts"
                    name="orders-filter"
                    value={OrderType.PHYSICAL}
                    text={`Physical (${count(OrderType.PHYSICAL)})`}
                    disabled={count(OrderType.PHYSICAL) === 0}
                    active={filter === OrderType.PHYSICAL}
                    onChange={setFilter}
                />
                <SearchField
                    placeholder="Search orders"
                    onQueryChange={(text) => setSearchTerm(text)}
                />
            </fieldset>
            <DiceTable
                tableName="orders"
                headerNames={[
                    'Order Number',
                    'Country',
                    'Order Date',
                    'Item(s)',
                    'Status',
                    'Date Shipped',
                ]}
                rows={rows}
                noRowsMessage={`We couldn't find a match for "${searchTerm}". Please try again.`}
            />
            <Pagination pagination={pagination} updatePagination={setPagination} />
        </div>
    );
};

export default OrdersTable;
