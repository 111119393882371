import React, { FunctionComponent, ReactElement, useContext, useState } from 'react';
import { HyruleToken } from '@nintendo/hyrule-react-commons';
import { Modal } from 'react-bootstrap';
import GameCard from './GameCard';
import CollapsiblePane from '../../shared/components/CollapsiblePane';
import LessThanFiveGamesPlayedMessage from './LessThanFiveGamesPlayedMessage';
import HyruleTokenContext from '../../contexts/HyruleTokenContext';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import ServerErrorMessage from '../../shared/components/outline-messages/ServerErrorMessage';
import { SearchContext } from '../../contexts/SearchContext';
import { useGamePlay } from './useGamePlay';
import { GamePlayHistoryList } from './GamePlayHistoryList';
import './GamePlayData.scss';

const GamePlayData: FunctionComponent = (): ReactElement => {
    const searchContext = useContext(SearchContext);
    const resolvedNaid = searchContext?.resolvedNaid || '';
    const correlationId = searchContext?.correlationId || '';
    const token: HyruleToken = useContext(HyruleTokenContext);
    const { data, loading, error } = useGamePlay(resolvedNaid, token, correlationId);
    const [isModalShow, setModalShow] = useState<boolean>(false);
    const lastFiveGames = data.slice(0, 5);

    const paneContent: ReactElement = error ? (
        <ServerErrorMessage />
    ) : (
        <div className="game-play-data d-flex justify-content-between">
            {lastFiveGames.map((gamePlayData) => (
                <GameCard
                    key={`game-${gamePlayData.title}`}
                    consumerId={gamePlayData.consumerId}
                    applicationId={gamePlayData.applicationId}
                    imageUrl={gamePlayData.imageUrl}
                    title={gamePlayData.title}
                    playTime={gamePlayData.playTime}
                />
            ))}
            {data.length < 5 ? (
                <LessThanFiveGamesPlayedMessage numGamesPlayed={data.length} />
            ) : null}
            <Modal show={isModalShow} onHide={() => setModalShow(false)} size="lg">
                <Modal.Header>
                    <h6 className="m-0">Game Play Data</h6>
                    <button
                        className="game-play-data__close-button button-reset"
                        type="button"
                        onClick={() => setModalShow(false)}
                        data-cy="close-game-play-modal"
                    >
                        &times;
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <GamePlayHistoryList games={data} />
                </Modal.Body>
            </Modal>
        </div>
    );

    const loadingSpinner: ReactElement = (
        <div className="text-center">
            <LoadingSpinner />
        </div>
    );

    return (
        <CollapsiblePane
            eventKey="GameInformation"
            headerText="Game Play Data"
            disablePaneContentPadding={!loading}
            dataCyForBody="game-play-data"
            additionalHeaderElement={
                !loading && data.length > 5 ? (
                    <button
                        className="btn btn-secondary btn-sm"
                        onClick={() => setModalShow(true)}
                        type="button"
                    >
                        View All Titles
                    </button>
                ) : undefined
            }
        >
            {loading && loadingSpinner}
            {!loading && paneContent}
        </CollapsiblePane>
    );
};

export default GamePlayData;
