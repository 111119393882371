import { HyruleToken } from '@nintendo/hyrule-react-commons';
import { useEffect, useState } from 'react';
import { GamePlayedDuration } from '../game-play/GamePlayedDuration';
import { GamePlayHistoryDetailApiModel } from '../../../api/models';
import { GamePlayHistoryDetailApi } from '../../../api';

interface UseGamePlayHistoryDetailData {
    data: GamePlayedDuration[];
    loading: boolean;
    errorMessage: string;
}

export const useGamePlayHistoryDetail = (
    shouldMakeRequest: boolean,
    consumerId: string,
    applicationId: string,
    daysAgo: number,
    token: HyruleToken,
    correlationId: string,
): UseGamePlayHistoryDetailData => {
    const [data, setData] = useState<GamePlayedDuration[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const getDetails = async (): Promise<void> => {
        setLoading(true);

        try {
            const apiModel: GamePlayHistoryDetailApiModel =
                await GamePlayHistoryDetailApi.getGamePlayHistoryDetail(
                    consumerId,
                    applicationId,
                    token,
                    correlationId,
                );
            const gamePlayedDurations = GamePlayHistoryDetailApi.mapToGamePlayedDuration(
                apiModel.played_days,
                daysAgo,
            );
            setData(gamePlayedDurations);
            setError('');
            setLoading(false);
        } catch {
            setError(
                'Sorry, we are currently having trouble retrieving this information in the pop-up.',
            );
        }
    };

    useEffect(() => {
        if (data.length > 0) {
            return;
        }

        if (shouldMakeRequest && applicationId && consumerId && token) {
            getDetails();
        }
    }, [shouldMakeRequest, applicationId, consumerId, token]);

    return {
        data,
        loading,
        errorMessage: error,
    };
};
