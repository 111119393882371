import { HyruleToken } from '@nintendo/hyrule-react-commons';
import React from 'react';

const defaultContext: HyruleToken = {
    accessToken: '',
    scopes: [],
};

const HyruleTokenContext = React.createContext(defaultContext);

export default HyruleTokenContext;
