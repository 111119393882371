import './GamePlayHistoryDetailsPopover.scss';
import React, { FunctionComponent, useContext, useState } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import { HyruleToken } from '@nintendo/hyrule-react-commons';
import HyruleTokenContext from '../../contexts/HyruleTokenContext';
import { SearchContext } from '../../contexts/SearchContext';
import { useGamePlayHistoryDetail } from '../hooks/useGamePlayHistoryDetail';
import LoadingSpinner from '../../shared/components/LoadingSpinner';

export interface GamePlayHistoryDetailModel {
    consumerId: string;
    applicationId: string;
    buttonLabel: string;
    daysAgo: number;
}

const GamePlayHistoryDetailsPopover: FunctionComponent<GamePlayHistoryDetailModel> = ({
    consumerId,
    applicationId,
    buttonLabel,
    daysAgo,
}) => {
    const [isPopoverOpen, setIsPopoverOpen] = useState<boolean>(false);
    const token: HyruleToken = useContext(HyruleTokenContext);
    const correlationId = useContext(SearchContext)?.correlationId || '';
    const {
        data: gamePlayedDurations,
        loading,
        errorMessage,
    } = useGamePlayHistoryDetail(
        isPopoverOpen,
        consumerId,
        applicationId,
        daysAgo,
        token,
        correlationId,
    );

    const closePopover = (): void => setIsPopoverOpen(false);
    const onToggle = (isOpen: boolean): void => setIsPopoverOpen(isOpen);

    return (
        <OverlayTrigger
            placement="right"
            overlay={
                <Popover
                    id="game-play-history-detail-popover"
                    className="game-play-history-detail-popover"
                >
                    <Popover.Title className="header">
                        <div className="title">Play Time:</div>
                        <button
                            className="button-reset close-button"
                            type="button"
                            onClick={(): void => closePopover()}
                        >
                            &times;
                        </button>
                    </Popover.Title>
                    <Popover.Content>
                        {errorMessage}
                        {!errorMessage && loading && <LoadingSpinner />}
                        {!errorMessage && !loading && gamePlayedDurations.length === 0 && (
                            <span>{`No play time logged in the last ${daysAgo} days!`}</span>
                        )}
                        {!errorMessage && !loading && gamePlayedDurations.length > 0 && (
                            <ul>
                                {gamePlayedDurations.map((gamePlayedDuration, index) => {
                                    const key = `game-play-history-details-popover-${index}`;
                                    return (
                                        <li
                                            key={key}
                                            data-cy={key}
                                            className="d-flex justify-content-between"
                                        >
                                            <strong>{gamePlayedDuration.dateTitle}:</strong>
                                            <div>
                                                {gamePlayedDuration.numHours
                                                    ? `${gamePlayedDuration.numHours}h`
                                                    : '--'}
                                            </div>
                                            <div>
                                                {gamePlayedDuration.numMinutes
                                                    ? `${gamePlayedDuration.numMinutes}m`
                                                    : '--'}
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </Popover.Content>
                </Popover>
            }
            trigger="click"
            show={isPopoverOpen}
            rootClose
            onToggle={onToggle}
        >
            <button
                className="button-reset link"
                type="button"
                onClick={(): void => setIsPopoverOpen(true)}
            >
                {buttonLabel}
            </button>
        </OverlayTrigger>
    );
};

export default GamePlayHistoryDetailsPopover;
