import { HyruleToken } from '@nintendo/hyrule-react-commons';
import dayjs from 'dayjs';
import { GameSummary, GameSummaryApiModel } from './models';
import { BaseApi } from './BaseApi';

import Utils from '../shared/Utils';
import { GamePlayDataModel } from '../components/account/game-play/GamePlayDataModel';

export class GamePlaySummaryApi {
    static async getGameHistorySummaries(
        naid: string,
        token: HyruleToken,
        correlationId: string,
    ): Promise<GameSummaryApiModel> {
        const { accountApiUrls } = BaseApi.ApiConfig();
        const url: string = accountApiUrls.gameSummaries(naid);
        const init: RequestInit = await BaseApi.MulesoftRequestInit('GET', token, correlationId);

        try {
            const response: Response = await fetch(url, init);
            return await response.json();
        } catch (error) {
            console.log(`Error: ${error}`);
            throw error;
        }
    }

    private static consolidateSummaries(summaries: GameSummary[]): GameSummary[] {
        const consolidatedDict: { [applicationId: string]: GameSummary } = {};

        for (let i = 0; i < summaries.length; i++) {
            const summary = summaries[i];
            const existingSummary: GameSummary = consolidatedDict[summary.application_id];

            if (existingSummary) {
                const existing: number = +existingSummary.totalplayduration;
                const incoming: number = +summary.totalplayduration;
                existingSummary.totalplayduration = existing + incoming;

                const lastPlayedIncoming: dayjs.Dayjs = dayjs.unix(summary.lastplayed_date);
                const lastPlayedCurrent: dayjs.Dayjs = dayjs.unix(existingSummary.lastplayed_date);
                if (lastPlayedIncoming.isAfter(lastPlayedCurrent)) {
                    existingSummary.lastplayed_date = summary.lastplayed_date;
                }
            } else {
                consolidatedDict[summary.application_id] = summary;
            }
        }

        return Object.values(consolidatedDict);
    }

    static mapToGamePlayDataModelList(model: GameSummaryApiModel): GamePlayDataModel[] {
        const consolidatedSummaries: GameSummary[] = GamePlaySummaryApi.consolidateSummaries(
            model.application_summary,
        );

        return consolidatedSummaries
            .sort((a, b) => b.lastplayed_date - a.lastplayed_date)
            .map((summary) => {
                const { hours, minutes } = Utils.secondsToHoursAndMinutes(
                    summary.totalplayduration,
                );
                const playTime: string = hours > 0 ? `${hours}h, ${minutes}m` : `${minutes}m`;

                const { imageuri, title } = summary.nsuids[0];

                return {
                    title,
                    playTime,
                    applicationId: summary.application_id,
                    consumerId: model.consumer_id,
                    imageUrl: imageuri,
                };
            });
    }
}
