import React, { FunctionComponent, ReactElement } from 'react';
import CollapsiblePane from '../CollapsiblePane';
import LoadingSpinner from '../LoadingSpinner';
import RepairsTable from './RepairsTable';
import RepairsNotFoundMessage from './RepairsNotFoundMessage';
import ServerErrorMessage from '../outline-messages/ServerErrorMessage';
import { RepairModel } from '../../../../types/RepairModel';

export interface RepairDataModel {
    repairs: RepairModel[];
    displayServerError: boolean;
    displayNotFound: boolean;
    isLoading: boolean;
    headerText: string;
}

const RepairsPane: FunctionComponent<RepairDataModel> = ({
    repairs,
    displayServerError,
    displayNotFound,
    isLoading,
    headerText,
}): ReactElement => {
    let paneContent: ReactElement = <RepairsTable repairs={repairs} />;

    if (isLoading) {
        paneContent = (
            <div className="text-center">
                <LoadingSpinner />
            </div>
        );
    } else if (displayServerError) {
        paneContent = <ServerErrorMessage />;
    } else if (displayNotFound) {
        paneContent = <RepairsNotFoundMessage />;
    }

    return (
        <CollapsiblePane
            eventKey="Repairs"
            headerText={headerText}
            disablePaneContentPadding={!isLoading}
            dataCyForBody="device-repairs"
        >
            {paneContent}
        </CollapsiblePane>
    );
};

export default RepairsPane;
