import React, { FunctionComponent, ReactElement } from 'react';
import DiceTable, {
    DiceTableRowInterface,
    formattedDateCell,
    valueOrUnavailable,
    unavailableText,
} from '../DiceTable';
import { RepairModel } from '../../../../types/RepairModel';
import TrackingInfoOverlay from '../tracking-info-overlay/TrackingInfoOverlay';
import './RepairsTable.scss';

const orderNumberColumn = (repair: RepairModel): ReactElement => (
    <span className="d-inline-block mr-2">{repairOrderNumber(repair)}</span>
);

const repairOrderNumber = (repair: RepairModel): ReactElement => {
    if (!repair.orderNumber) return <span>{unavailableText}</span>;

    if (repair.orderNumber && repair.source === 'Mize')
        return (
            <TrackingInfoOverlay
                orderNumber={repair.orderNumber.toString()}
                outboundLabels={repair.outboundLabels}
                inboundLabels={repair.inboundLabels}
            >
                <span>{repair.orderNumber}</span>
            </TrackingInfoOverlay>
        );

    return <span>{repair.orderNumber}</span>;
};

const RepairsTable: FunctionComponent<{ repairs: RepairModel[] }> = ({ repairs }) => {
    const rows: DiceTableRowInterface[] = repairs.map((repair) => ({
        columns: [
            orderNumberColumn(repair),
            valueOrUnavailable(repair.country),
            valueOrUnavailable(repair.status),
            formattedDateCell(repair.dateSetup, unavailableText),
            formattedDateCell(repair.dateReceived, unavailableText),
            formattedDateCell(repair.dateShipped, unavailableText),
            valueOrUnavailable(repair.item),
        ],
    }));

    return (
        <DiceTable
            tableName="repairs"
            headerNames={[
                'Order Number',
                'Country',
                'Status',
                'Date Setup',
                'Date Received',
                'Date Shipped',
                'Item(s)',
            ]}
            rows={rows}
        />
    );
};

export default RepairsTable;
