import './AssociatedAccountTable.scss';
import React, { FunctionComponent } from 'react';
import Table from 'react-bootstrap/Table';
import Utils from '../../../shared/Utils';
import { AssociatedAccountModel } from './AssociatedAccountModel';

export interface AssociatedAccountTableProps {
    accounts: AssociatedAccountModel[];
    onAccountSelect: (accountNumber: string) => void;
    selectedAccountId: string;
}

const AssociatedAccountTable: FunctionComponent<AssociatedAccountTableProps> = ({
    onAccountSelect,
    accounts,
    selectedAccountId,
}) => {
    const rowClassName = (account: AssociatedAccountModel): string | undefined =>
        selectedAccountId === account.nintendoAccountId ? 'active' : undefined;

    const rowOnClick = (rowModel: AssociatedAccountModel): void =>
        onAccountSelect(rowModel.nintendoAccountId);

    return (
        <Table className="associated-account-table" size="sm">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Associated Email Address</th>
                </tr>
            </thead>
            <tbody>
                {accounts.map((account, index) => {
                    const emailOrNaid: string = !Utils.isValidEmail(account.email)
                        ? account.nintendoAccountId
                        : account.email;
                    return (
                        <tr
                            key={account.nintendoAccountId}
                            onClick={(): void => rowOnClick(account)}
                            className={rowClassName(account)}
                        >
                            <td>{index + 1}</td>
                            <td title={emailOrNaid}>{emailOrNaid}</td>
                        </tr>
                    );
                })}
            </tbody>
        </Table>
    );
};

export default AssociatedAccountTable;
