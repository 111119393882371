import React, { FunctionComponent } from 'react';
import OutlineMessage from '../../shared/components/outline-messages/OutlineMessage';
import { ReactComponent as ShellOutlineImage } from '../../../assets/shell-outline.svg';

const NoDetailsFoundMessage: FunctionComponent = () => (
    <OutlineMessage
        message="There currently isn't any Device information associated with this Nintendo Account."
        outlineImage={<ShellOutlineImage name="shell-outline-image" />}
        messageLocation="message-right"
    />
);

export default NoDetailsFoundMessage;
