import React, { FunctionComponent } from 'react';
import StatusScreen from './StatusScreen';
import Footer from '../../../Footer';
import ShellColoredPng from '../../../../assets/shell-colored.png';
import ShellColoredWebp from '../../../../assets/shell-colored.webp';

const NotFoundScreen: FunctionComponent = () => (
    <>
        <StatusScreen
            dataCy="nas-search-not-found-component"
            statusImage={
                <picture>
                    <source srcSet={ShellColoredWebp} type="image/webp" />
                    <img src={ShellColoredPng} alt="" />
                </picture>
            }
            statusTitle="No Results Found"
            statusMessage="Sorry, we couldn't find any information matching your search."
        />
        <Footer />
    </>
);

export default NotFoundScreen;
