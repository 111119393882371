import './AccountDeviceDetails.scss';
import dayjs from 'dayjs';
import React, { FunctionComponent, ReactElement } from 'react';
import AccountDeviceDetailsTable, {
    AccountDeviceDetailsTableEntryModel,
} from './AccountDeviceDetailsTable';
import CollapsiblePane from '../../shared/components/CollapsiblePane';
import LoadingSpinner from '../../shared/components/LoadingSpinner';
import { ReactComponent as ShellOutlineImage } from '../../../assets/shell-outline.svg';
import { LastUpdated } from '../../shared/components/last-updated/LastUpdated';

export interface AccountDeviceDetailsProps {
    isLoading: boolean;
    devices: AccountDeviceDetailsTableEntryModel[];
    lastUpdated: dayjs.Dayjs | null;
}

const AccountDeviceDetails: FunctionComponent<AccountDeviceDetailsProps> = ({
    isLoading,
    devices,
    lastUpdated,
}): ReactElement => {
    const loadingSpinner: ReactElement = (
        <div className="p-3 text-center">
            <LoadingSpinner />
        </div>
    );

    const noInformationAvailable: ReactElement = (
        <div
            className="account-device-details-placeholder"
            data-cy="account-device-details-placeholder"
        >
            <div className="image">
                <ShellOutlineImage name="shell-outline-image" />
            </div>
            <div className="text">
                There currently isn't any Device information associated with this Nintendo account.
            </div>
        </div>
    );

    let paneContent: ReactElement;

    if (isLoading) {
        paneContent = loadingSpinner;
    } else if (!devices || devices.length === 0) {
        paneContent = noInformationAvailable;
    } else {
        paneContent = <AccountDeviceDetailsTable entries={devices} />;
    }

    return (
        <CollapsiblePane
            eventKey="DeviceDetails"
            headerText="Device Details"
            disablePaneContentPadding
            dataCyForBody="account-device-details"
            additionalHeaderElement={
                lastUpdated ? <LastUpdated dateTime={lastUpdated} /> : undefined
            }
        >
            {paneContent}
        </CollapsiblePane>
    );
};

export default AccountDeviceDetails;
