import { HyruleToken } from '@nintendo/hyrule-react-commons';
import { useEffect, useState } from 'react';
import { AssociatedAccountModel } from './AssociatedAccountModel';
import Utils from '../../../shared/Utils';
import { DeviceAccountApiModel, DeviceAccountApiModelResponse } from '../../../api/models';
import { DeviceApi } from '../../../api';
import { NintendoAccountApi } from '../../../api/NintendoAccountApi';

interface AssociatedAccountsHookData {
    data: AssociatedAccountModel[];
    loading: boolean;
    notFound: boolean;
    error: boolean;
}

export const useAssociatedAccounts = (
    serialNumber: string,
    token: HyruleToken,
    correlationId: string,
): AssociatedAccountsHookData => {
    const [data, setData] = useState<AssociatedAccountModel[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    const [notFound, setNotFound] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const getAssociatedAccountDetails = async (): Promise<void> => {
        setLoading(true);

        const deviceAccountsResponse: DeviceAccountApiModelResponse =
            await DeviceApi.getAccountsByDeviceSerialNumber(serialNumber, token, correlationId);

        const { notFound, serverError } = deviceAccountsResponse;
        setError(serverError);
        setNotFound(notFound);

        if (notFound || serverError) {
            setData([]);
            setLoading(false);
            return;
        }

        const deviceAccounts: DeviceAccountApiModel[] = deviceAccountsResponse.accounts;

        const deviceAccountsWithEmails: DeviceAccountApiModel[] = await Promise.all(
            deviceAccounts.map(async (account) => {
                const nasResponse = await NintendoAccountApi.getNaidOrEmail(
                    { email: '', naid: account.naid },
                    token,
                    correlationId,
                );
                return { ...account, email_address: nasResponse.email ? nasResponse.email : '' };
            }),
        );

        setData(DeviceApi.mapToAssociatedAccountModel(deviceAccountsWithEmails));
        setLoading(false);
    };

    useEffect(() => {
        if (Utils.isValidSerialNumber(serialNumber) && token.accessToken) {
            getAssociatedAccountDetails();
        } else {
            setData([]);
        }
    }, [serialNumber, token]);

    return {
        data,
        loading,
        notFound,
        error,
    };
};
